import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { languageSVC, translate } from "app/language/service"
import { store } from "app/store/store"
import { JLayer, JProject } from "jmapcloud-types"
import { messageSVC } from "message/service"
import pLimit from "p-limit"
import { ALL_SERVER_DISTANCE_UNITS, JProjectAcl, JProjectSubmitValues, TASK_LEVELS } from "project/model"
import projectRPO from "project/repository"
import { setDisplayCards, setQuickFilter } from "project/store"
import { getNgBaseUrl } from "server/tools/common"
import { JDataGridPagedResponse } from "ui/tools/grid"
import { getUserOrganization } from "user/tools/common"

export function getProjects(page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JProject>> {
  return projectRPO.get(getUserOrganization().id, page, size, sortModel, filterModel)
}

export function getProject(projectId: string): Promise<JProject> {
  return projectRPO.getProject(getUserOrganization().id, projectId)
}

export function getLayer(projectId: string, layerId: string): Promise<JLayer> {
  return projectRPO.getLayer(getUserOrganization().id, projectId, layerId)
}

export function getProjectCount(): Promise<number> {
  return projectRPO.getCount(getUserOrganization().id)
}

export function setProjectDisplayCards(displayCards: boolean): void {
  if (typeof displayCards !== "boolean") {
    throw Error("Display grid value must be a boolean")
  }
  store.dispatch(setDisplayCards(displayCards))
}

export function setProjectQuickFilter(filter: string): void {
  store.dispatch(setQuickFilter(filter))
}

// export function setProjectListFilter(filter: string): void {
//   if (typeof filter !== "string") {
//     throw Error("Filter value must be a string")
//   }
//   store.dispatch(setFilter(filter))
// }

// export function setProjectListSortDirection(listSort: LIST_SORT_DIRECTIONS): void {
//   if (!ALL_LIST_SORT_DIRECTIONS.includes(listSort)) {
//     throw Error(`List sort direction "${listSort}" invalid. Available: ${ALL_LIST_SORT_DIRECTIONS.join(", ")} `)
//   }
//   store.dispatch(setListSort(listSort))
// }

export function createProject(project: JProjectSubmitValues): Promise<string> {
  if (typeof project !== "object") {
    return Promise.reject(`project must be an object, found ${typeof project}`)
  }

  if (typeof project.backgroundColor !== "string") {
    return Promise.reject("backgroundColor must be a string")
  }

  if (typeof project.defaultSelectionColor !== "string") {
    return Promise.reject("defaultSelectionColor must be a string")
  }

  if (!ALL_SERVER_DISTANCE_UNITS.includes(project.displayUnit)) {
    return Promise.reject(`Invalid displayUnit "${project.displayUnit}". Available: ${ALL_SERVER_DISTANCE_UNITS.join(", ")}`)
  }

  if (!ALL_SERVER_DISTANCE_UNITS.includes(project.distanceUnit)) {
    return Promise.reject(`Invalid distanceUnit "${project.distanceUnit}". Available: ${ALL_SERVER_DISTANCE_UNITS.join(", ")}`)
  }

  if (!ALL_SERVER_DISTANCE_UNITS.includes(project.mapUnit)) {
    return Promise.reject(`Invalid mapUnit "${project.mapUnit}". Available: ${ALL_SERVER_DISTANCE_UNITS.join(", ")}`)
  }

  if (project.tags && !Array.isArray(project.tags)) {
    return Promise.reject("tags must be an array of string")
  }

  return projectRPO.create(getUserOrganization().id, project)
}

export function updateProject(projectId: string, project: JProjectSubmitValues) {
  return projectRPO.update(getUserOrganization().id, projectId, project)
}

export function addTagToProject(projectId: string, tag: string) {
  return projectRPO.addTag(getUserOrganization().id, projectId, tag)
}

export function deleteTagFromProject(projectId: string, tag: string) {
  return projectRPO.deleteTag(getUserOrganization().id, projectId, tag)
}

export function deleteProject(projectId: string) {
  return projectRPO.delete(getUserOrganization().id, projectId)
}

export function getProjectAcls(projectId: string) {
  return projectRPO.getPermissionACLs(getUserOrganization().id, projectId)
}

export function getUserProjectPermissions(projectId: string) {
  return projectRPO.getUserPermissions(getUserOrganization().id, projectId)
}

export function getProjectExtent(projectId: string) {
  return projectRPO.getProjectExtent(getUserOrganization().id, projectId)
}

export function updateProjectAcls(projectId: string, acls: JProjectAcl[]) {
  return projectRPO.updatePermissionACLs(getUserOrganization().id, projectId, acls)
}

export function copyProjectPublicUrlToClipboard(project: JProject) {
  const url = `${getNgBaseUrl()}?ngProjectId=${project.id}&ngOrganizationId=${project.organizationId}&ngAnonymous=true`
  navigator.clipboard.writeText(url)
  messageSVC.info(translate("project.public.copyUrl.message"))
}

export async function getLayerCount(projectId: string) {
  const count = await projectRPO.getLayerCount(getUserOrganization().id, projectId)
  return { projectId, count }
}

export function getProjectMetrics(projectId: string) {
  return projectRPO.getProjectMetrics(getUserOrganization().id, projectId)
}

const N_CONCURRENT_API_CALLS = 10
const limit = pLimit(N_CONCURRENT_API_CALLS)

// The layer count is not included in the project data that was already downloaded, so we launch
// a bunch of (limited) parallel calls to fetch it, and add it to the returned project array
export async function getProjectLayerCounts(projectsWithoutLayerCounts: JProject[]): Promise<JProject[]> {
  const ps = projectsWithoutLayerCounts.map(p => limit(() => getLayerCount(p.id)))
  const layerCountResult = await Promise.all(ps)
  // Transform array of results into map (projectId -> count)
  const layerCountMap = Object.fromEntries(layerCountResult.map(r => [r.projectId, r.count]))
  const projectsWithLayerCounts = projectsWithoutLayerCounts.map(p => ({
    ...p,
    layerCount: layerCountMap[p.id]
  }))
  return projectsWithLayerCounts
}

export function validateTaskLevels(minLevel: number | string, maxLevel: number | string, levels: TASK_LEVELS): boolean {
  if (levels === TASK_LEVELS.ALL) {
    return true
  }
  if (minLevel === "" || maxLevel === "") {
    return false
  }
  if (minLevel > maxLevel) {
    return false
  }
  return true
}

export function getCoordinatesById(id: string) {
  const element = document.getElementById(id)
  if (!element) {
    return
  }
  const rect = element.getBoundingClientRect()
  const coords = {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height
  }
  return coords
}

export function naturalSortString(a: string, b: string): number {
  return a.localeCompare(b, languageSVC.getLocale(), { numeric: true, sensitivity: "base" })
}
