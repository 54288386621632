import { ajaxSVC } from "app/ajax/service"
import {
  DEFAULT_SERVER_VERSION,
  JSERVER_IDENTITY_PROVIDER_TYPES,
  JSERVER_MAIN_VERSIONS,
  JSERVER_MICRO_SERVICE_IDS,
  JServerAnyIdentityProvider,
  JServerIdentityProviderAuth0Spa,
  JServerInfo,
  JServerMicroServiceById,
  JServerRepository
} from "./model"
import { getRestBaseUrl } from "./tools/common"

export const serverRPO: JServerRepository = {
  getInfo(): Promise<JServerInfo> {
    return new Promise<JServerInfo>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getRestBaseUrl()}/server-info`
          })
          .then((response: any) => resolve(checkAndGetServerInfo(response.result)))
          .catch((error: any) => {
            console.error("Error while getting server info", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting server info", ex)
        reject(ex)
      }
    })
  }
}

function checkAndGetServerInfo(raw: any): JServerInfo {
  if (typeof raw !== "object") {
    throw Error("checkAndGetServerInfo(): missing data")
  }
  if (typeof raw.status !== "string") {
    throw Error("status is not a string.")
  }
  if (!raw.version) {
    raw.version = "JMap Cloud"
  }
  return {
    version: {
      title: DEFAULT_SERVER_VERSION,
      mainVersion: JSERVER_MAIN_VERSIONS.V0,
      buildNumber: 0
    },
    identityProviders: checkAndGetIdentityProviders(raw.identityProviders),
    microServiceById: checkAndGetMicroServices(raw)
  }
}

function checkAndGetMicroServices(rawServerInfo: any): JServerMicroServiceById {
  if (!Array.isArray(rawServerInfo.services)) {
    throw Error("No service defined in saas server")
  }
  const rawServices = rawServerInfo.services as any[]
  // Gateway service is not a real service, we add it on-the fly
  if (!rawServices.find(s => s.id === JSERVER_MICRO_SERVICE_IDS.GATEWAY)) {
    rawServices.push({
      id: JSERVER_MICRO_SERVICE_IDS.GATEWAY,
      name: "JMap Cloud API Gateway",
      version: "JMap Cloud API Gateway v0",
      status: rawServerInfo.status,
      url: getRestBaseUrl()
    })
  }
  const allKnownServiceIds = Object.values(JSERVER_MICRO_SERVICE_IDS)
  const serverServiceIds = rawServices.map(s => s.id)
  // check that all known services are there
  for (const knownServiceId of allKnownServiceIds) {
    if (!serverServiceIds.includes(knownServiceId)) {
      throw Error(`Service "${knownServiceId}" is not present on server, cannot continue`)
    }
  }
  const microServiceById: JServerMicroServiceById = {}
  for (const service of rawServices) {
    const serviceId = service.id
    if (!allKnownServiceIds.includes(serviceId)) {
      console.warn(`Unknown service id = "${serviceId}", will skip`)
      continue
    }
    microServiceById[serviceId] = {
      id: service.id,
      name: service.name,
      version: service.version,
      status: service.status,
      restBaseUrl: service.url
    }
  }
  return microServiceById
}

function checkAndGetIdentityProviders(rawIdentityProviders: any): JServerAnyIdentityProvider[] {
  if (!rawIdentityProviders || !Array.isArray(rawIdentityProviders)) {
    throw Error("checkAndGetIdentityProviders(): missing data")
  }
  const allProviders: JServerAnyIdentityProvider[] = []

  for (let i = 0; i < rawIdentityProviders.length; i++) {
    const id = `idp-${i}`
    const provider = rawIdentityProviders[i]
    switch (provider.type as JSERVER_IDENTITY_PROVIDER_TYPES) {
      case JSERVER_IDENTITY_PROVIDER_TYPES.AUTH0_SPA:
        const auth0Provider: JServerIdentityProviderAuth0Spa = {
          id,
          name: provider.name,
          type: JSERVER_IDENTITY_PROVIDER_TYPES.AUTH0_SPA,
          domain: provider.domain,
          audience: provider.audience,
          clientId: provider.clientId,
          logoutRedirectUrl: provider.logoutRedirectUrl
        }
        allProviders.push(auth0Provider)
        break
      default:
        console.warn(`Unknown Identity provider type "${provider.type}"`)
        break
    }
  }
  return allProviders
}
