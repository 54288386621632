import { Box, Grid, Theme, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import React from "react"
import { connect } from "react-redux"
import { LocalesPopupMenu } from "user/components/LocalesPopupMenu"

export interface JCodeVersionsProps {
  serverVersion: string
}

const CodeVersionsFn = (props: JCodeVersionsProps): JSX.Element => {
  const { serverVersion } = props
  return (
    <Box
      className="jmap-code-version"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Typography sx={{ fontSize: ".6rem", color: (theme: Theme) => theme.palette.text.secondary }}>{translate("app.version.notice", { appVersion: APP_VERSION })}</Typography>
                  </Box>
                </Grid>
                {serverVersion && (
                  <Grid item>
                    <Typography sx={{ fontSize: ".6rem", color: (theme: Theme) => theme.palette.text.secondary }}>{translate("server.version.notice", { serverVersion })}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <LocalesPopupMenu />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export const CodeVersions = connect(
  (state: JRootState) =>
    ({
      serverVersion: state.server.version.title
    }) as JCodeVersionsProps
)(CodeVersionsFn)
