import { Avatar } from "@mui/material"
import { translate } from "app/language/service"
import { messageSVC } from "message/service"
import React from "react"
import { ROLES } from "user/model"
import { getAvatarLetterFromRole } from "user/utils"
import { StatusChip } from "./components/StatusChip"
import { STATUS_CHIP_LEVELS } from "./model"

export function copyFieldValueInClipboard(field: any, fieldName: string) {
  navigator.clipboard.writeText(field)
  const message = messageSVC.info(translate("field.value.copied.message", { fieldName }))
}

export function getRoleChips(roles: ROLES[]): JSX.Element[] {
  return roles
    .sort()
    .map(role => (
      <StatusChip key={role} sx={{ marginRight: "0.5rem" }} avatar={<Avatar>{getAvatarLetterFromRole(role)}</Avatar>} level={STATUS_CHIP_LEVELS.NEUTRAL} label={translate(`user.role.${role}`)} />
    ))
}
