import { EventModule } from "../event/EventModule"
import { JLanguageEventLocaleChangeParams, JLanguageEventModule, JLANGUAGE_EVENTS } from "./model"

class LanguageEventModule extends EventModule implements JLanguageEventModule {
  public on = {
    initialized: (listenerId: string, fn: () => void): void => {
      this.addListenerFn(JLANGUAGE_EVENTS.INITIALIZED, listenerId, fn)
    },
    localeChange: (listenerId: string, fn: (params: JLanguageEventLocaleChangeParams) => void): void => {
      this.addListenerFn(JLANGUAGE_EVENTS.LOCALE_CHANGE, listenerId, fn)
    }
  }
}

export const languageEventModule: LanguageEventModule = new LanguageEventModule()
