import { JPage } from "page/model"

export function isActiveMenu(toTestPage: JPage, activePage: JPage): boolean {
  if (toTestPage === activePage) {
    return true
  } else if (activePage.parentPath) {
    return toTestPage.path === activePage.parentPath
  }
  return false
}
