import { Theme } from "@mui/material"

export const MAIN_LAYOUT_HEADER_MENU_BUTTON_ID: string = "main-layout-header-menu-button"
export const MAIN_LAYOUT_HEADER_MENU_ID: string = "main-layout-header-menu"

export const MENU_WIDTH_IN_REM = 5
export const MAIN_TITLE_HEIGHT_IN_REM = 3.5

export const PREF_UI_THEME_DARK: string = "jmap.app.theme.dark"

export enum STATUS_CHIP_LEVELS {
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
  NEUTRAL = "NEUTRAL"
}

export type StatusChipLevelColors = {
  [key in STATUS_CHIP_LEVELS]: { foreground: string; background: string }
}

export enum UI_EVENTS {
  CONTAINER_SIZE_CHANGED = "CONTAINER_SIZE_CHANGED"
}

// we define the module here instead of in type.d.ts because otherwise we have TypeScript compilation errors
// material-ui:
// if we need to add custom colors to the MUI palette, we must uncomment the following code and configure the
// custom palette entries accordignly:
/*
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    custom: {
      state1: React.CSSProperties["color"]
      state2: React.CSSProperties["color"]
    }
  }

  interface PaletteOptions {
    custom: {
      state1: React.CSSProperties["color"]
      state2: React.CSSProperties["color"]
    }
  }
}
*/

export interface JUIService {
  setDarkThemeActive(isDarkThemeActive: boolean): void
  isDarkThemeActive(): boolean
}

export interface JUiState {
  theme: Theme
}
