import { InputProps, StandardTextFieldProps, TextField, TextFieldProps } from "@mui/material"
import React from "react"

export interface TextLabelProps extends StandardTextFieldProps {
  value: string | number
  monospace?: boolean
}

// This is just a wrapper around a readonly Textfield component, to make sure that we maintain a coherent visual style
export const TextLabel = (props: TextLabelProps) => {
  const { monospace, ...propsWithoutMonospace } = props

  const textfieldProps: TextFieldProps = {
    ...propsWithoutMonospace,
    InputProps: {
      ...props.InputProps,
      readOnly: true,
      disableUnderline: true
    } as InputProps,
    InputLabelProps: {
      ...props.InputLabelProps,
      focused: false,
      shrink: true
    }
  }
  if (monospace) {
    // using monospace font in this context could not always be desirable
    textfieldProps.InputProps!.sx = { fontFamily: "IBM Plex Mono", fontSize: "0.75rem" }
  }
  return <TextField {...textfieldProps} fullWidth />
}
