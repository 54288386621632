import { Box, InputLabel, Stack } from "@mui/material"
import Color from "color"
import { addPresetColor, getPresetColors } from "form/tools/color"
import React, { useEffect } from "react"
import { ColorPicker } from "ui/components/ColorPicker"
import { ColorSwatch } from "ui/components/ColorSwatch"

export interface JColorFieldProps {
  id: string
  title: string
  value: string
  required?: boolean
  onChange: (value: string) => any
}

export const ColorField = React.forwardRef((props: JColorFieldProps, ref) => {
  const previousColor = React.useRef<Color>(Color(props.value).alpha(1))
  const [color, setColor] = React.useState<Color>(previousColor.current)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  useEffect(() => setColor(Color(props.value)), [props.value])

  return (
    <Box id={props.id}>
      <Stack>
        <InputLabel shrink={true}>
          {props.title}
          {props.required ? " *" : ""}
        </InputLabel>
        <ColorSwatch color={color} onClick={event => setAnchorEl(event.currentTarget)} />
      </Stack>
      <ColorPicker
        anchorElement={anchorEl}
        color={color}
        presetColors={getPresetColors()}
        disableAlpha={true}
        onChange={event => {
          const rgb = event.rgb
          setColor(Color.rgb(rgb.r, rgb.g, rgb.b).alpha(rgb.a !== undefined ? rgb.a : 1))
        }}
        onClose={() => {
          if (previousColor.current.hex() !== color.hex()) {
            addPresetColor(color.hex())
          }
          props.onChange(color.hex())
          previousColor.current = color
          setAnchorEl(null)
        }}
        open={open}
      />
    </Box>
  )
})
