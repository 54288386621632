import { ajaxSVC } from "app/ajax/service"
import { store } from "app/store/store"
import { JOrganization } from "organization/model"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { getUserOrganization } from "user/tools/common"
import { JUser, JUserRepository, JUserUpdateParams, MEMBER_ROLES, ROLES } from "./model"

const userRPO: JUserRepository = {
  getUserById(userId: string): Promise<JUser> {
    return new Promise<JUser>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/users/${encodeURIComponent(userId)}`
          })
          .then((response: any) => resolve(getUserFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting user from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting user", ex)
        reject(ex)
      }
    })
  },
  updateUser(params: JUserUpdateParams): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .patch({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/users/${store.getState().user.email}`,
            params
          })
          .then(resolve)
          .catch((error: any) => {
            console.error("Server error while updating user", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while updating user", ex)
        reject(ex)
      }
    })
  },
  getUserIdentity(accessToken: string, userOrganization?: JOrganization): Promise<JUser> {
    return new Promise<JUser>(async (resolve, reject) => {
      ajaxSVC
        .get({
          url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/users/self`,
          accessToken
        })
        .then(response => {
          const organization = userOrganization ?? getUserOrganization()
          if (organization) {
            // fetch member roles and merge them with the user's roles
            ajaxSVC
              .get({
                url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organization.id}/members/${response.result.email}`, // same as "/members/self"
                accessToken
              })
              .then(response2 => {
                resolve({
                  name: response.result.name,
                  email: response.result.email,
                  organizations: response.result.organizations,
                  roles: (response.result.roles as ROLES[]).concat(response2.result.roles as MEMBER_ROLES[])
                })
              })
              .catch(error => reject(error))
          } else {
            resolve({
              name: response.result.name,
              email: response.result.email,
              organizations: response.result.organizations,
              roles: response.result.roles
            })
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

function getUserFromRaw(rawUser: any): JUser {
  return {
    name: rawUser.name,
    email: rawUser.email,
    organizations: Array.isArray(rawUser.organizations) ? rawUser.organizations : [],
    roles: Array.isArray(rawUser.roles) ? rawUser.roles : []
  }
}

export default userRPO
