import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { store } from "app/store/store"
import { jobEventModule } from "job/event"
import { CANCELABLE_JOB_STATUSES, JJob, JOB_EVENTS, JOB_STATUS, JProcess } from "job/model"
import jobRPO from "job/repository"
import { messageSVC } from "message/service"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { JDataGridPagedResponse } from "ui/tools/grid"
import { getUserOrganization } from "user/tools/common"
import { reload } from "./store"

export function getProcesses() {
  return jobRPO.getAllProcesses(getUserOrganization().id)
}

export function getJobs(processes: JProcess[], page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JJob>> {
  return jobRPO.get(getUserOrganization().id, processes, page, size, sortModel, filterModel)
}

export function getJob(jobId: string) {
  return jobRPO.getJob(getUserOrganization().id, jobId)
}

export async function getAllRunningMvtCacheJobs(): Promise<JJob[]> {
  let jobs: JJob[] = []
  let resp: JDataGridPagedResponse<JJob>
  let page = 0
  const organizationId = getUserOrganization().id
  do {
    resp = await jobRPO.get(
      organizationId,
      [], // no need for this in this context
      page,
      50,
      [], // don't sort
      {
        items: [
          {
            columnField: "process",
            operatorValue: "in",
            value: ["VTCS:SEED_MVT_CACHE", "VTCS:RESEED_MVT_CACHE", "VTCS:TRUNCATE_MVT_CACHE"]
          },
          { columnField: "status", operatorValue: "in", value: ["accepted", "running"] }
        ]
      }
    )
    jobs = jobs.concat(resp.result)
    page += 1
  } while (jobs.length < resp.page.totalElements)
  return jobs
}

// There should be only one!
export async function getRunningMvtCacheJobForProject(projectId: string): Promise<JJob | null> {
  const jobs = await getAllRunningMvtCacheJobs()
  for (const job of jobs) {
    if (job.inputs?.projectId === projectId) {
      return job
    }
  }
  return null
}

export async function executeProcess(process: string, params: any) {
  return jobRPO.executeProcess(getUserOrganization().id, process, params)
}

export function cancelJob(jobId: string) {
  return jobRPO.cancelJob(getUserOrganization().id, jobId)
}

export function confirmCancelJob(jobId: string): Promise<boolean> {
  if (typeof jobId !== "string" || jobId === "") {
    return Promise.reject("Invalid job id")
  }
  return new Promise<boolean>((resolve, reject) => {
    messageSVC.confirmDialog({
      // must use yes/no here because main action is cancel
      confirmButtonLabel: translate("button.yes"),
      cancelButtonLabel: translate("button.no"),
      isCancelDefault: true,
      title: translate("job.delete.title"),
      message: translate("job.delete.message"),
      onCancel: () => resolve(false),
      onSuccess: () => {
        jobRPO
          .cancelJob(getUserOrganization().id, jobId)
          .then(() => {
            messageSVC.success(translate("job.delete.success"))
            jobEventModule.notify(JOB_EVENTS.CANCEL, { jobId })
            store.dispatch(reload()) // reload grid
            resolve(true)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
    })
  })
}

export function isJobCancelable(jobStatus: JOB_STATUS): boolean {
  return CANCELABLE_JOB_STATUSES.includes(jobStatus)
}

export function getJobStatusChipLevel(status: JOB_STATUS): STATUS_CHIP_LEVELS {
  switch (status) {
    case JOB_STATUS.ACCEPTED:
    case JOB_STATUS.SUCCESS:
      return STATUS_CHIP_LEVELS.GREEN

    case JOB_STATUS.RUNNING:
      return STATUS_CHIP_LEVELS.ORANGE

    case JOB_STATUS.FAILED:
      return STATUS_CHIP_LEVELS.RED

    case JOB_STATUS.DISMISSED:
    default:
      return STATUS_CHIP_LEVELS.NEUTRAL
  }
}
