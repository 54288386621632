import { JServerState } from "../model"

export function getDefaultServerState(): JServerState {
  return {
    isReady: false,
    isLoading: false,
    hasLoadingError: false,
    version: {
      title: "",
      mainVersion: 0,
      buildNumber: 0
    },
    microServiceById: {},
    identityProviders: []
  }
}
