import { ACTIONS } from "app/store/actions"
import { Action } from "redux"
import { JServerInfo } from "server/model"

export interface JServerActionSetServerInfo extends Action {
  serverInfo: JServerInfo
}

export interface JServerActionSetServerInfoIsLoading extends Action {
  isLoading: boolean
}

export interface JServerActionSetServerInfoLoadingError extends Action {
  hasLoadingError: boolean
}

export const serverActionCreator = {
  setServerInfo(serverInfo: JServerInfo): JServerActionSetServerInfo {
    return {
      type: ACTIONS.SERVER_SET_SERVER_INFO,
      serverInfo
    }
  },
  setServerInfoIsLoading(isLoading: boolean): JServerActionSetServerInfoIsLoading {
    return {
      type: ACTIONS.SERVER_SET_SERVER_INFO_IS_LOADING,
      isLoading
    }
  },
  setServerInfoLoadingError(hasLoadingError: boolean): JServerActionSetServerInfoLoadingError {
    return {
      type: ACTIONS.SERVER_SET_SERVER_INFO_LOADING_ERROR,
      hasLoadingError
    }
  }
}
