import { ModalProps, Popover } from "@mui/material"
import Color from "color"
import React from "react"
import { ColorChangeHandler, SketchPicker } from "react-color"

export interface JColorPickerProps {
  disableAlpha?: boolean
  open: boolean
  anchorElement: Element | null
  onClose: ModalProps["onClose"]
  onChange: ColorChangeHandler
  color: Color
  presetColors: string[]
}

export const ColorPicker = (props: JColorPickerProps): JSX.Element => {
  const { open, anchorElement, onClose, onChange, color, disableAlpha, presetColors } = props
  return (
    <Popover
      id="jmap-color-picker"
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: "left"
      }}
    >
      <SketchPicker disableAlpha={disableAlpha} color={{ r: color.red(), g: color.green(), b: color.blue(), a: color.alpha() }} presetColors={presetColors} onChange={onChange} />
    </Popover>
  )
}
