import { Box, LinearProgress, SxProps, Theme, Typography } from "@mui/material"
import React from "react"

interface JLinearLoaderProps {
  text: string
  id?: string
  className?: string
  sx?: SxProps<Theme> | undefined
}

export const LinearLoader = (props: JLinearLoaderProps) => (
  <Box
    id={props.id}
    sx={{
      "display": "flex",
      "alignItems": "center",
      "flexDirection": "column",
      "justifyContent": "center",
      "width": "100%",
      "& .linear-progress": {
        width: "100%",
        marginTop: ".5rem",
        marginBottom: "1rem"
      },
      ...props.sx
    }}
    className={props.className || ""}
  >
    <Typography color="textSecondary">{props.text}</Typography>
    <LinearProgress color="secondary" className="linear-progress"></LinearProgress>
  </Box>
)
