import { useState } from "react"
import { JErrorResponse } from "./model"

// the useErrorHandling hook is passed an defaultErrorMessage as parameter that will usually be display before the errors returned.
// The default error displayed in the front-end will be   ${defaultErrorMessage} ("error1", "error2")

export const useErrorHandling = (defaultErrorMessage: string = "") => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const handleError = (errorResponse: JErrorResponse, startingErrorMessage: string = defaultErrorMessage) => {
    let errorMessageDisplayed = startingErrorMessage
    let errorsReceived
    let errorStatus

    try {
      errorsReceived = ` (${errorResponse.response.data.errors.map((error: string) => `"${error}"`).join(", ")})`
    } catch (ex) {
      errorsReceived = ""
    }

    // If the errorResponse is not in the expected format JErrorResponse, we only display the initial message
    try {
      errorStatus = errorResponse.response.status
    } catch (error) {
      setErrorMessage(errorMessageDisplayed)
      setHasError(true)
      return
    }

    switch (errorStatus) {
      case 400:
      case 401:
      case 403:
      case 404:
        errorMessageDisplayed += errorsReceived
        break
      case 500:
      default:
        break
    }

    setErrorMessage(errorMessageDisplayed)
    setHasError(true)
  }

  const resetError = () => {
    setHasError(false)
    setErrorMessage("")
  }

  // set an error message without any error errorResponse
  const setErrorMessageAndActivateError = (newErrorMessage: string) => {
    setErrorMessage(newErrorMessage)
    setHasError(true)
  }

  return { hasError, errorMessage, setErrorMessageAndActivateError, handleError, resetError }
}
