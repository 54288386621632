import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, InputAdornment, TextField, Theme, Tooltip, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import { messageSVC } from "message/service"
import { updateOrganization } from "organization/tools/common"
import React, { useState } from "react"
import { LinearLoader } from "ui/components/LinearLoader"
import { checkMinMaxLength } from "ui/tools/form"
import { getUserOrganization } from "user/tools/common"
import { useErrorHandling } from "app/hook"

export const OrganizationMain = (): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.update.submit.error"))
  const organization = getUserOrganization()
  const [initialValues, setInitialValues] = useState({
    name: organization.name,
    id: organization.id
  })
  return (
    <Box
      sx={{
        "display": "flex",
        "flexDirection": "column",
        "overflow": "auto",
        "width": "40rem",
        "& .organization-main-page-form": {
          width: "40rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifiContent: "center",
          backgroundColor: (theme: Theme) => theme.palette.background.default
        },
        "& .organization-main-page-form > *": {
          marginTop: "2rem"
        }
      }}
    >
      <Formik
        initialValues={initialValues}
        validate={values => {
          if (hasError) {
            resetError()
          }
          const errors: any = {}
          if (!values.name) {
            errors.name = translate("label.field.required")
          } else {
            checkMinMaxLength(errors, "name", values.name, 255)
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (hasError) {
            resetError()
          }
          await updateOrganization(values)
            .then(updatedOrganization =>
              setInitialValues({
                id: updatedOrganization.id,
                name: updatedOrganization.name
              })
            )
            .catch(error => {
              setSubmitting(false)
              console.error(error)
              handleError(error)
            })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setValues }) => {
          const hasNameError: boolean = touched.name === true && errors.name !== undefined
          const hasChanged: boolean = !deepEquals(values, initialValues)
          const canSubmit: boolean = !isSubmitting && hasChanged && Object.keys(errors).length === 0
          return (
            <form onSubmit={handleSubmit} className="organization-main-page-form">
              <TextField
                id="organization-main-page-field-name"
                required
                name="name"
                sx={{ width: "100%" }}
                size="medium"
                error={hasNameError}
                label={translate("label.name")}
                value={values.name}
                helperText={hasNameError ? errors.name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                id="organization-main-page-field-id"
                name="id"
                sx={{ width: "100%" }}
                size="medium"
                label={translate("label.id")}
                value={organization.id}
                disabled={true}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    sx: {
                      width: "auto",
                      flexGrow: 1
                    }
                  },
                  sx: {
                    width: "auto"
                  },
                  endAdornment: (
                    <InputAdornment sx={{ flexGrow: 2, marginRight: "auto" }} position="end">
                      <Tooltip title={translate("organization.main.form.id.copyId.tooltip")}>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(organization.id)
                              messageSVC.info(translate("organization.main.form.id.copyId.message"))
                            }}
                          >
                            <FontAwesomeIcon icon={light("copy")} />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
              {hasError && (
                <Typography color="error" sx={{ width: "80%", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}
              {isSubmitting && <LinearLoader sx={{ width: "80%", marginBottom: "-1rem" }} text={translate("organization.update.submit.loading")} />}
              <Box
                sx={{
                  "width": "100%",
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "end",
                  "marginBottom": "2rem",
                  "& > *": {
                    marginLeft: "1rem !important"
                  },
                  "& > .button": {
                    opacity: 1,
                    transition: "opacity 500ms ease"
                  },
                  "& > .button.hidden": {
                    opacity: 0
                  }
                }}
              >
                <Button id="organization-main-page-button-cancel" className={!canSubmit ? "button hidden" : "button"} disabled={!canSubmit} variant="outlined" onClick={() => setValues(initialValues)}>
                  {translate("button.cancel")}
                </Button>
                <Button id="organization-main-page-button-save" className={!canSubmit ? "button hidden" : "button"} type="submit" disabled={!canSubmit}>
                  {translate("button.update")}
                </Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
