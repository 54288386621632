import { Auth0Client, IdToken } from "@auth0/auth0-spa-js"
import { JEventModule } from "app/event/model"
import { JOrganization } from "organization/model"

export const EMAIL_REGEX: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const EMPTY_TOKEN_VALUE: string = "-1"
export const BAD_CREDENTIAL_ERROR_KEY: string = "user.login.error.credential"
export const NO_ORGANIZATION_ERROR_KEY: string = "user.login.error.no.organization"
export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_ACCEPTED_SPECIAL_CHARACTERS = " !@#$%^&*"

export enum ROLES {
  SYS_ADMIN = "SYS_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_EDITOR = "ORG_EDITOR",
  ORG_VIEWER = "ORG_VIEWER",
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER"
}

export type MEMBER_ROLES = ROLES.ORG_ADMIN | ROLES.ORG_EDITOR | ROLES.ORG_VIEWER

export type USER_ROLES = ROLES.SYS_ADMIN

export type API_KEY_ROLES = ROLES.ORG_EDITOR | ROLES.ORG_VIEWER

export const ALL_ROLES = Object.values(ROLES)

export const ALL_MEMBER_ROLES: MEMBER_ROLES[] = [ROLES.ORG_ADMIN, ROLES.ORG_EDITOR, ROLES.ORG_VIEWER]

export const ALL_USER_ROLES: USER_ROLES[] = [ROLES.SYS_ADMIN]

export const ALL_API_KEY_ROLES: API_KEY_ROLES[] = [ROLES.ORG_EDITOR, ROLES.ORG_VIEWER]

export enum USER_EVENTS {
  LOGIN = "login",
  LOGOUT = "logout"
}

export interface JUser {
  name: string
  email: string
  organizations: JOrganization[]
  roles: ROLES[]
}

export interface JTokenInfo {
  accessToken: string
  accessTokenExpirationInMSecs: number
  idToken: IdToken
  picture: string
}

export interface JUserState extends JUser, JTokenInfo {
  isLoggingIn: boolean
  isUserLoggedIn: boolean
  userLoginError: string
  organizationLoginError: string
  selectedOrganization: JOrganization | null
  switchOrganisationDialogIsOpen: boolean
  callbackAtTokenRefresh: (() => void) | null
}

export interface JUserService {
  getOrganizationId(): string | undefined
  getUserName(): string
  getPreference(name: string): Promise<string | null>
  hasPreference(name: string): Promise<boolean>
  removePreference(name: string): Promise<string | null>
  setPreference(name: string, value: string | undefined): Promise<void>
  logout(): Promise<void>
  isLoggedIn(): boolean
  getToken(): string
}

export interface JUserServiceConfig {
  auth0Client: Auth0Client
  sessionKeeperTimeout: number | undefined
  localStorageAvailable: boolean
}

export interface JUserRepository {
  getUserById(userId: string): Promise<JUser>
  getUserIdentity(accessToken: string, userOrganization?: JOrganization): Promise<JUser>
  updateUser(params: JUserUpdateParams): Promise<void>
}

export interface JUserEventLoginParams {
  userInfo: JUser & JTokenInfo
}

export interface JUserEventModule extends JEventModule {
  on: {
    login(listenerId: string, fn: (params: JUserEventLoginParams) => void): void
    logout(listenerId: string, fn: () => void): void
  }
}

export interface JPasswordPolicyCompliance {
  hasMinimumLength: boolean
  hasLowercaseLetters: boolean
  hasUppercaseLetters: boolean
  hasNumbers: boolean
  hasSpecialCharacters: boolean
}

export interface JUserUpdateParams {
  name?: string
  currentPassword?: string
  newPassword?: string
}
