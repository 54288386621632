import { store } from "app/store/store"
import { parseBoolean } from "app/utils/common"
import { PREF_UI_THEME_DARK } from "ui/model"
import { uiActionCreator } from "ui/store/actions"
import { userSVC } from "user/service"
import { localizedThemes } from "./theme"

export function applyUiFeaturePreferences(): void {
  // load theme from preference
  userSVC
    .getPreference(PREF_UI_THEME_DARK)
    .then(uiThemeIsDarkPreferenceValue => {
      if (uiThemeIsDarkPreferenceValue !== null && typeof parseBoolean(uiThemeIsDarkPreferenceValue) === "boolean") {
        const locale = store.getState().language.locale
        const isDark: boolean = parseBoolean(uiThemeIsDarkPreferenceValue) as boolean
        store.dispatch(uiActionCreator.setTheme(isDark ? localizedThemes[locale].dark : localizedThemes[locale].light))
      }
    })
    .catch(reason => {
      console.error("cannot get user preference for application theme", reason)
    })
}
