import { ACTIONS } from "app/store/actions"
import { Action, Reducer } from "redux"
import { JLanguageState } from "../model"
import { JLanguageSetLocale } from "./actions"
import { getDefaultLanguageState } from "./state"

export const languageReducer: Reducer<JLanguageState, Action> = (state: JLanguageState | undefined, action: Action): JLanguageState => {
  if (!state) {
    state = getDefaultLanguageState()
  }
  switch (action.type) {
    case ACTIONS.LANGUAGE_SET_LOCALE: {
      const data = action as JLanguageSetLocale
      return {
        ...state,
        locale: data.locale
      }
    }

    default:
      return state
  }
}
