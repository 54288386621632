import { ACTIONS } from "app/store/actions"
import { JPage } from "page/model"
import { Action } from "redux"

export interface JActionSetPages extends Action {
  pages: JPage[]
}

export interface JActionActivatePage extends Action {
  page: JPage
}

export const pageActionCreator = {
  setAll(pages: JPage[]): JActionSetPages {
    return {
      type: ACTIONS.PAGE_SET_ALL,
      pages
    }
  },
  activate(page: JPage): JActionActivatePage {
    return {
      type: ACTIONS.PAGE_ACTIVATE,
      page
    }
  }
}
