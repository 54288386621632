import { configureStore } from "@reduxjs/toolkit"
import { appEventModule } from "app/event"
import { languageReducer } from "app/language/store/reducer"
import { APP_EVENTS } from "app/model"
import fileReducer from "file/store"
import jobReducer from "job/store"
import messageReducer from "message/store"
import { organizationReducer } from "organization/store/reducer"
import { pageReducer } from "page/store/reducer"
import projectReducer from "project/store"
import { serverReducer } from "server/store/reducer"
import sdsReducer from "spatialdatasource/store"
import studioReducer from "studio/store"
import { uiReducer } from "ui/store/reducer"
import userReducer from "user/store"

// Note that configureStore is the Redux Toolkit's modern replacement of createStore
export const store = configureStore({
  reducer: {
    ui: uiReducer,
    language: languageReducer,
    user: userReducer,
    server: serverReducer,
    message: messageReducer,
    page: pageReducer,
    organization: organizationReducer,
    project: projectReducer,
    sds: sdsReducer,
    file: fileReducer,
    job: jobReducer,
    studio: studioReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false // for the sdsReducer.uploadByFileId map, which contains tus.Upload objects (which mutate)
    })
})

appEventModule.notify(APP_EVENTS.STORE_READY, {})
