import { EventModule } from "app/event/EventModule"
import { JMemberEventDeleteParams, JMemberEventModule, MEMBER_EVENTS } from "./model"

class MemberEventModule extends EventModule implements JMemberEventModule {
  public on = {
    delete: (listenerId: string, fn: (params: JMemberEventDeleteParams) => void): void => {
      this.addListenerFn(MEMBER_EVENTS.DELETE, listenerId, fn)
    }
  }
}

export const memberEventModule: MemberEventModule = new MemberEventModule()
