import { appCFG } from "app/config"
import { JHistoryListener, JHistoryService } from "app/history/model"
import { startHistoryListeners } from "./listeners"
import { checkParameterName, getHashParameters, popHashParameters, setHashParameters, transformSearchParamsIntoHashParams } from "./tools/common"

const history = appCFG.history

history.currentHashParameters = getHashParameters()

startHistoryListeners()

export const historySVC: JHistoryService = {
  transformSearchParamsIntoHashParams,
  goBack(): void {
    history.browserHistory.back()
  },
  goForward(): void {
    history.browserHistory.forward()
  },
  getHashParameter(parameterName: string): string {
    checkParameterName(parameterName)
    const result: string | undefined = history.currentHashParameters[parameterName]
    return result !== undefined ? result : ""
  },
  getHashParameters(): { [key: string]: string } {
    return JSON.parse(JSON.stringify(history.currentHashParameters))
  },
  pushHashParameters(parameterName: string, parameterValue: any): void {
    checkParameterName(parameterName)
    history.currentHashParameters[parameterName] = parameterValue
    setHashParameters(history.currentHashParameters)
  },
  popHashParameters,
  onParameterChange(parameterName: string, fn: JHistoryListener): string {
    checkParameterName(parameterName)
    if (!fn || typeof fn !== "function") {
      throw Error("Bad fn param")
    }
    const listenerId: string = String(history.nextListenerId++)
    history.allListeners.push({ id: listenerId, parameterName, fn })
    return listenerId
  },
  removePropertyChangeListener(listenerId: string): void {
    checkParameterName(listenerId)
    history.allListeners = history.allListeners.filter(listener => listener.id === listenerId)
  }
}
