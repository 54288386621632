import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { Stack } from "@mui/system"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { dateSVC } from "app/tool/date/service"
import { JApiKey } from "organization/model"
import { organizationActionCreator } from "organization/store/actions"
import { getOrganizationApiKeys } from "organization/tools/apikey"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { useHoverableDataGridRows } from "ui/hooks"
import { getNameInDescription } from "user/tools/display"
import { OrganizationApiKeyCreateDialog } from "./OrganizationApiKeyCreateDialog"
import { OrganizationApiKeyDetailsDialog } from "./OrganizationApiKeyDetailsDialog"
import { OrganizationApiKeyGridRowMenu } from "./OrganizationApiKeyGridRowMenu"

export const OrganizationApiKeyGrid = (): JSX.Element => {
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [selectedApiKey, setSelectedApiKey] = React.useState<JApiKey | null>(null)
  const [apikeys, setApikeys] = React.useState<JApiKey[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isCreateDialogOpened, setIsCreateDialogOpened] = React.useState(false)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const { pageSize } = useAppSelector(state => state.organization)

  React.useEffect(() => {
    setIsLoading(true)
    getOrganizationApiKeys()
      .then(fetchedApiKeys => {
        setIsLoading(false)
        setApikeys(fetchedApiKeys)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        setHasLoadingError(true)
      })
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  const columns: GridColDef[] = [
    { field: "title", headerName: translate("label.title"), minWidth: 200, flex: 2 },
    {
      field: "action",
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <OrganizationApiKeyGridRowMenu
              onClose={() => setHoveredRowId(null)}
              apiKeyId={params.id as string}
              onShowEditDialog={() => {
                setSelectedApiKey(params.row)
              }}
              onDelete={reloadGrid}
            />
          )
        }
      }
    },
    { field: "id", headerName: translate("label.id"), minWidth: 280, flex: 2 },
    {
      field: "createdBy",
      headerName: translate("label.createdBy"),
      renderCell: params => getNameInDescription(params.row.createdBy),
      minWidth: 200,
      flex: 2
    },
    {
      field: "creationDate",
      headerName: translate("label.creationDate"),
      renderCell: params => dateSVC.format(params.row.creationDate),
      minWidth: 100,
      flex: 1
    },
    {
      field: "expiresAt",
      headerName: translate("label.expiresAt"),
      renderCell: params => dateSVC.format(params.row.expiresAt),
      minWidth: 100,
      flex: 1
    }
  ]

  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" justifyContent="end" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Button startIcon={<FontAwesomeIcon className="create-button" icon={regular("plus")} />} onClick={() => setIsCreateDialogOpened(true)}>
          {translate("organization.apikey.create.title")}
        </Button>
      </Stack>

      <PortalDataGrid
        rows={apikeys}
        columns={columns}
        rowType="organization.apikey"
        className="apikey-list-data-grid"
        loading={isLoading}
        pagination={false}
        pageSize={pageSize}
        onPageSizeChange={s => store.dispatch(organizationActionCreator.setPageSize(s))}
        error={hasLoadingError}
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "title", sort: "asc" }]
          }
        }}
      />
      {selectedApiKey && <OrganizationApiKeyDetailsDialog apikey={selectedApiKey} onClose={() => setSelectedApiKey(null)} />}
      {isCreateDialogOpened && <OrganizationApiKeyCreateDialog onClose={() => setIsCreateDialogOpened(false)} onCreate={reloadGrid} />}
    </Stack>
  )
}
