import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { ajaxSVC } from "app/ajax/service"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JDataGridPagedResponse, getFormattedGridQueryForJMC } from "ui/tools/grid"
import { JJob, JJobRepository, JProcess } from "./model"

const jobRPO: JJobRepository = {
  getAllProcesses(organizationId: string): Promise<JProcess[]> {
    return new Promise<JProcess[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.PROCESS)}/rest/v1/organizations/${organizationId}/processes`
          })
          .then((response: any) => resolve(response.processes))
          .catch((error: any) => {
            console.error(`Server error while getting all processes of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all processes of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  get(organizationId: string, processes: JProcess[], page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JJob>> {
    return new Promise<JDataGridPagedResponse<JJob>>(async (resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.PROCESS)}/rest/v1/organizations/${organizationId}/jobs`,
            params: {
              page: page + 1, // watch out API's page is 1-indexed
              size,
              q: getFormattedGridQueryForJMC(filterModel, sortModel)
            }
          })
          .then((response: any) => {
            const apiResponse = {
              page: response.page,
              result: response.result.map((j: any) => getJobFromRaw(j, organizationId, processes))
            }
            resolve(apiResponse)
          })
          .catch((error: any) => {
            console.error(`Server error while getting all jobs of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all jobs of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  async getJob(organizationId: string, jobId: string): Promise<JJob> {
    const resp = await ajaxSVC.get({
      url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.PROCESS)}/rest/v1/organizations/${organizationId}/jobs/${jobId}`
    })
    return getJobFromRaw(resp.job, organizationId, [])
  },
  cancelJob(organizationId: string, jobId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.PROCESS)}/rest/v1/organizations/${organizationId}/jobs/${jobId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting job "${jobId}" of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while deleting job "${jobId}" of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  async executeProcess(organizationId: string, process: string, params: any): Promise<JJob> {
    const resp = await ajaxSVC.post({
      url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.PROCESS)}/rest/v1/organizations/${organizationId}/processes/${process}/execution`,
      ...{ params }
    })
    return getJobFromRaw(resp.job, organizationId, [])
  }
}

function getJobFromRaw(rawJob: any, organizationId: string, processes: JProcess[]): JJob {
  return {
    id: rawJob.jobId,
    organizationId,
    status: rawJob.status,
    processId: rawJob.processId,
    type: rawJob.type,
    message: rawJob.message,
    creationDate: new Date(rawJob.created),
    startDate: rawJob.started ? new Date(rawJob.started) : undefined,
    finishDate: rawJob.finished ? new Date(rawJob.finished) : undefined,
    updateDate: rawJob.updated ? new Date(rawJob.updated) : undefined,
    progress: rawJob.progress,
    inputs: rawJob.inputs,
    isProcessCancelable: processes.find(p => p.id === rawJob.processId)?.jobControlOptions?.includes("dismiss") === true
  }
}

export default jobRPO
