import { translate } from "app/language/service"
import { store } from "app/store/store"
import { messageSVC } from "message/service"
import { JExtension, JOrganization, JOrganizationExtension, JOrganizationExtensionConfiguration, JOrganizationUpdateParams, JTag } from "organization/model"
import { organizationRPO } from "organization/repository"
import { selectUserOrganization, setUserOrganizations } from "user/store"
import { getUserOrganization } from "user/tools/common"

export async function updateOrganization(params: JOrganizationUpdateParams): Promise<JOrganization> {
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (typeof params.name !== "string" || !params.name) {
    return Promise.reject("params.name must be a non empty string")
  }
  try {
    const organizationId = await organizationRPO.update(params)
    const newOrganization = await organizationRPO.getOrganizationById(organizationId)
    messageSVC.success(translate("organization.update.success", { organizationName: params.name }))
    const organizations = store.getState().user.organizations
    const newOrganizations = organizations.map(org => (org.id === params.id ? newOrganization : org))
    store.dispatch(setUserOrganizations(newOrganizations))
    store.dispatch(selectUserOrganization(newOrganization))
    return newOrganization
  } catch (error) {
    return Promise.reject(error)
  }
}

export function getAllTags(): Promise<JTag[]> {
  return organizationRPO.getAllTags(getUserOrganization().id)
}

export async function getOrganizationUsage(): Promise<number> {
  let usage = 0
  try {
    const [fusMetrics, dasMetrics, misMetrics] = await Promise.all([
      organizationRPO.getFUSMetrics(getUserOrganization().id),
      organizationRPO.getDASMetrics(getUserOrganization().id),
      organizationRPO.getMISMetrics(getUserOrganization().id)
    ])
    usage += fusMetrics.totalFileSize + dasMetrics.totalDataSize + misMetrics.totalFileSize
  } catch (error) {
    console.error("An error occured while fetching metrics on organization", error)
    throw Error("An error occured while fetching metrics on organization")
  }
  return usage
}

export function getExtensions(): Promise<JExtension[]> {
  return organizationRPO.getExtensions()
}

export function getExtensionConfiguration(projectId: string, extensionId: string): Promise<JOrganizationExtensionConfiguration> {
  return organizationRPO.getExtensionConfiguration(getUserOrganization().id, projectId, extensionId)
}

export function updateExtensionConfiguration(projectId: string, extensionId: string, params: JOrganizationExtensionConfiguration): Promise<string> {
  return organizationRPO.updateExtensionConfiguration(getUserOrganization().id, projectId, extensionId, params)
}

export const getOrganizationActiveExtensions = async () => {
  const [fetchedExtensions, fetchedOrganisationExtensions] = await Promise.all([getExtensions(), getOrganizationExtensions()])
  const orgExtIds = fetchedOrganisationExtensions.map(orgExt => orgExt.extensionId)
  const activeExtensions = fetchedExtensions.filter(ext => orgExtIds.includes(ext.id))
  return activeExtensions
}

export function getOrganizationExtensions(): Promise<JOrganizationExtension[]> {
  return organizationRPO.getOrganizationExtensions(getUserOrganization().id)
}

export function activateOrganisationExtension(extensionId: string): Promise<string> {
  return organizationRPO.activateOrganisationExtension(getUserOrganization().id, extensionId)
}

export function deactivateOrganisationExtension(extensionId: string): Promise<string> {
  return organizationRPO.deactivateOrganisationExtension(getUserOrganization().id, extensionId)
}
