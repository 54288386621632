import { Button, Checkbox, DialogActions, DialogContent, LinearProgress, LinearProgressProps, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import { Box, Stack } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { JProject, JServerExtent } from "jmapcloud-types"
import { JJob, JOB_STATUS } from "job/model"
import { cancelJob, executeProcess, getJob, getRunningMvtCacheJobForProject } from "job/utils"
import { SelectExtentMap } from "map/components/SelectExtentMap"
import { windowCoords as IWindowCoords } from "map/model"
import { messageSVC } from "message/service"
import { useInterval } from "project/hooks"
import { TASK_LEVELS, TASK_PROCESS, TASK_TYPES } from "project/model"
import { setProjectMvtCacheDialog } from "project/store"
import { getCoordinatesById, getProjectExtent, validateTaskLevels } from "project/utils"
import { Resizable } from "re-resizable"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"

const JOB_STATUS_REFRESH_INTERVAL_IN_MSECS = 1000

const defaultValues = {
  process: TASK_PROCESS.SEED,
  type: TASK_TYPES.FILL,
  levels: TASK_LEVELS.ALL,
  minLevel: "",
  maxLevel: ""
}

const levelsChoices: number[] = []
for (let i = 0; i <= 22; i++) {
  levelsChoices.push(i)
}

interface ProjectMvtTileCacheFormProps {
  project: JProject
}

interface taskFormValues {
  process: TASK_PROCESS
  type: TASK_TYPES
  levels: TASK_LEVELS
  minLevel: number | string
  maxLevel: number | string
}
export const ProjectMvtTileCacheForm = ({ project }: ProjectMvtTileCacheFormProps) => {
  const [runningJob, setRunningJob] = React.useState<JJob | null>(null)

  useInterval(
    () => {
      if (runningJob) {
        getJob(runningJob.id).then(job => {
          if ([JOB_STATUS.ACCEPTED, JOB_STATUS.RUNNING].includes(job.status)) {
            setRunningJob(job)
          } else {
            setRunningJob(null)
          }
        })
      }
    },
    runningJob ? JOB_STATUS_REFRESH_INTERVAL_IN_MSECS : null
  )

  return <>{runningJob ? <TaskProgress runningJob={runningJob} setRunningJob={setRunningJob} /> : <TaskCreationForm project={project} setRunningJob={setRunningJob} />}</>
}

const TaskCreationForm = ({ project, setRunningJob }: any) => {
  const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling()
  const [isLoading, setIsLoading] = React.useState(false)
  const [taskType, setTaskType] = React.useState<string>(TASK_TYPES.FILL)
  const [extentEnabled, setExtentEnabled] = React.useState(true)
  const [windowCoords, setWindowCoords] = React.useState<IWindowCoords | undefined>()
  const [initialExtent, setInitialExtent] = React.useState<JServerExtent | null>(project.initialExtent)
  const [extent, setExtent] = React.useState<JServerExtent | null>(project.initialExtent)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const setProjectExtent = async () => {
      const projectExtent = await getProjectExtent(project.id)
      setInitialExtent(projectExtent)
    }
    if (!project.initialExtent) {
      setProjectExtent()
    }
  }, [])

  React.useEffect(() => {
    if (taskType === "seed") {
      setExtentEnabled(true)
    } else if (taskType === "truncate") {
      setExtentEnabled(false)
    }
  }, [taskType])

  React.useEffect(() => {
    setIsLoading(true)
    getRunningMvtCacheJobForProject(project.id)
      .then(job => {
        setRunningJob(job)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, translate("server.error"))
        console.error(error)
      })
  }, [project])

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<taskFormValues>({
    defaultValues
  })
  const watchLevels = watch("levels")

  const onSubmit = async (values: taskFormValues) => {
    values.process = values.type === TASK_TYPES.FILL ? TASK_PROCESS.SEED : TASK_PROCESS.TRUNCATE
    if (hasError) {
      resetError()
    }
    if (!validateTaskLevels(values.minLevel, values.maxLevel, values.levels)) {
      setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
      return
    }

    const submitValues = extentEnabled
      ? { inputs: { projectId: project.id, crs: "EPSG:4326", xMin: extent?.x1, xMax: extent?.x2, yMin: extent?.y1, yMax: extent?.y2, zMin: values.minLevel, zMax: values.maxLevel } }
      : { inputs: { projectId: project.id, crs: "EPSG:4326", zMin: values.minLevel, zMax: values.maxLevel } }

    setIsLoading(true)

    try {
      const job = await executeProcess(values.process, submitValues)
      setRunningJob(job)
    } catch (error: any) {
      handleError(error, translate("server.error"))
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <DialogContent sx={{ padding: "30px 0", borderBottom: 1, borderColor: "divider" }}>
        <Stack gap={10} display="flex" direction="row" justifyContent="space-between">
          <Stack display="flex" direction="column" flex={1} gap={4}>
            <Grid item>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setTaskType(e.target.value)
                    }}
                    label={translate("mvt.cache.dialog.task.type")}
                    defaultValue={defaultValues.type}
                    fullWidth
                    select
                  >
                    <MenuItem value={TASK_TYPES.FILL}>{translate("mvt.cache.dialog.task.type.fill")}</MenuItem>
                    <MenuItem value={TASK_TYPES.DELETE}>{translate("mvt.cache.dialog.task.type.delete")}</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="levels"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setValue("minLevel", "")
                      setValue("maxLevel", "")
                    }}
                    label={translate("mvt.cache.dialog.task.levels")}
                    defaultValue={defaultValues.levels}
                    fullWidth
                    select
                  >
                    <MenuItem value={TASK_LEVELS.ALL}>{translate("mvt.cache.dialog.task.levels.all")}</MenuItem>
                    <MenuItem value={TASK_LEVELS.SOME}>{translate("mvt.cache.dialog.task.levels.some")}</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Stack direction="row" gap={5}>
              <Controller
                control={control}
                name="minLevel"
                disabled={watchLevels === TASK_LEVELS.ALL}
                render={({ field }) => (
                  <TextField {...field} label={translate("mvt.cache.dialog.task.levels.min")} defaultValue={defaultValues.minLevel} fullWidth select>
                    {levelsChoices.map((level, index) => (
                      <MenuItem key={index} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="maxLevel"
                disabled={watchLevels === TASK_LEVELS.ALL}
                render={({ field }) => (
                  <TextField {...field} label={translate("mvt.cache.dialog.task.levels.max")} defaultValue={defaultValues.maxLevel} fullWidth select>
                    {levelsChoices.map((level, index) => (
                      <MenuItem key={index} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Stack>
          <Stack display="flex" direction="column" flex={1}>
            <Stack direction="row" alignItems="center" justifyContent="left" gap={1}>
              <Checkbox
                checked={extentEnabled}
                disabled={taskType === "seed"}
                onChange={e => {
                  setExtentEnabled(e.target.checked)
                  if (!e.target.checked) {
                    setExtentEnabled(false)
                  }
                }}
              />
              <Typography>{translate("mvt.cache.dialog.task.extent.specify")}</Typography>
            </Stack>
            <Grid sx={{ width: "100%", marginTop: "10px", aspectRatio: "3/2", position: "relative", overflow: "hidden" }}>
              {windowCoords && <SelectExtentMap project={project} setExtent={setExtent} windowCoords={windowCoords} initialExtent={initialExtent} />}
              <MapOverlay setWindowCoords={setWindowCoords} extentEnabled={extentEnabled} />
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", marginTop: "20px" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5em" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setRunningJob(null)
                dispatch(setProjectMvtCacheDialog(null))
              }}
            >
              {translate("mvt.cache.dialog.button.close")}
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {translate("mvt.cache.launch.process")}
            </Button>
          </>
        </Stack>
      </DialogActions>
    </form>
  )
}
interface MapOverlayProps {
  setWindowCoords: (coords: IWindowCoords) => void
  extentEnabled: boolean
}

const MapOverlay = ({ setWindowCoords, extentEnabled }: MapOverlayProps) => {
  const [canClickThrough, setCanClickThrough] = useState(true)
  const theme = useTheme()
  const MapOverlayDimensions = getCoordinatesById("MapOverlay")
  const windowRef = React.useRef(null)
  React.useEffect(() => {
    const windowCoords = getCoordinatesById("window")
    if (!windowCoords) {
      return
    }
    setWindowCoords(windowCoords)
  }, [windowRef.current])

  const handleResizeStop = () => {
    const windowCoords = getCoordinatesById("window")
    if (!windowCoords) {
      return
    }
    setWindowCoords(windowCoords)
    setCanClickThrough(true)
  }

  return (
    <Grid id="MapOverlay" sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", pointerEvents: "none" }}>
      <Grid container display="flex" direction="column" alignItems="center" justifyContent="center" sx={{ height: "100%", width: "100%", pointerEvents: "none" }}>
        {MapOverlayDimensions && extentEnabled && (
          <Resizable
            maxHeight={"100%"}
            maxWidth={"100%"}
            defaultSize={{ width: `${MapOverlayDimensions.width - 50}px`, height: `${MapOverlayDimensions.height - 50}px` }}
            style={{ boxShadow: "0 0 0 2000px rgba(128, 128, 128, 0.5)", pointerEvents: canClickThrough ? "none" : "auto", position: "relative" }}
            onResizeStop={handleResizeStop}
          >
            <Grid onMouseEnter={() => setCanClickThrough(true)} id="window" ref={windowRef} sx={{ height: "100%", width: "100%", margin: "auto" }}></Grid>{" "}
            <WindowBorders handleCanClickThrough={setCanClickThrough} color={theme.palette.primary.main} borderWidth="4px" />
          </Resizable>
        )}
      </Grid>
    </Grid>
  )
}

interface WindowBordersProps {
  handleCanClickThrough: (canClickThrough: boolean) => void
  color: string
  borderWidth: string
}
const WindowBorders = ({ handleCanClickThrough, color, borderWidth }: WindowBordersProps) => (
  <>
    <Grid onMouseEnter={() => handleCanClickThrough(false)} sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: borderWidth, backgroundColor: color, pointerEvents: "all" }} />
    <Grid onMouseEnter={() => handleCanClickThrough(false)} sx={{ position: "absolute", top: 0, right: 0, height: "100%", width: borderWidth, backgroundColor: color, pointerEvents: "all" }} />
    <Grid onMouseEnter={() => handleCanClickThrough(false)} sx={{ position: "absolute", top: 0, left: 0, height: borderWidth, width: "100%", backgroundColor: color, pointerEvents: "all" }} />
    <Grid onMouseEnter={() => handleCanClickThrough(false)} sx={{ position: "absolute", bottom: 0, left: 0, height: borderWidth, width: "100%", backgroundColor: color, pointerEvents: "all" }} />
  </>
)

interface taskProgressProps {
  runningJob: JJob
  setRunningJob: (job: JJob | null) => void
}
const TaskProgress = ({ runningJob, setRunningJob }: taskProgressProps) => {
  const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const getRunningJobDescription = () => {
    if (!runningJob) {
      return ""
    }
    let desc = ""
    if (runningJob.processId === "VTCS:SEED_MVT_CACHE") {
      desc = translate("mvt.cache.caching")
    } else {
      desc = translate("mvt.cache.deleting")
    }
    desc += " - "
    if ("zMin" in runningJob.inputs || "zMax" in runningJob.inputs) {
      desc += `${translate("mvt.cache.some.levels")} (${runningJob.inputs.zMin} - ${runningJob.inputs.zMax})`
    } else {
      desc += translate("mvt.cache.all.levels")
    }
    return desc
  }

  const handleCancelJob = () => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.yes"),
      cancelButtonLabel: translate("button.no"),
      isCancelDefault: true,
      title: translate("job.delete.title"),
      message: translate("job.delete.message"),
      onSuccess: () => {
        if (runningJob) {
          cancelJob(runningJob.id).catch(error => {
            handleError(error, translate("server.error"))
            console.error(error)
          })
          setRunningJob(null)
        }
      }
    })
  }

  return (
    <>
      <DialogContent sx={{ padding: "30px 0", borderBottom: 1, borderColor: "divider" }}>
        <Stack direction="column">
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Grid>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {translate("mvt.cache.dialog.task.running")}
              </Typography>
              <Grid>{getRunningJobDescription()}</Grid>
            </Grid>
            <Typography variant="h6" sx={{ color: `${theme.palette.primary.dark} ` }}>
              {runningJob.progress ?? 0}%
            </Typography>
          </Stack>

          <LinearProgressWithLabel value={runningJob.progress ?? 0} />

          <Grid>{translate("mvt.cache.dialog.task.wait")}</Grid>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", marginTop: "20px" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5em" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleCancelJob()
              }}
            >
              {translate("mvt.cache.dialog.task.cancel")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setProjectMvtCacheDialog(null))
              }}
            >
              {translate("mvt.cache.dialog.button.close")}
            </Button>
          </>
        </Stack>
      </DialogActions>
    </>
  )
}
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" sx={{ height: "10px" }} {...props} />
      </Box>
    </Box>
  )
}
