import { CircularProgress, Tooltip } from "@mui/material"
import { translate } from "app/language/service"
import { confirmCancelJob } from "job/utils"
import { messageSVC } from "message/service"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"

interface JJobGridRowActionMenuProps {
  jobId: string
  onClose: () => void
}

export const JobGridRowActionMenu = (props: JJobGridRowActionMenuProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const isDisabled: boolean = isDeleting
  const items: JButtonMenuItem[] = [
    {
      title: translate("job.cancel.tooltip"),
      disabled: isDisabled,
      onClick: () => {
        if (isDisabled) {
          return
        }
        setIsDeleting(true)
        confirmCancelJob(props.jobId)
          .then(() => setIsDeleting(false))
          .catch(error => {
            console.error(error)
            messageSVC.error(translate("job.delete.error"))
            setIsDeleting(false)
          })
      }
    }
  ]
  return isDeleting ? (
    <Tooltip title={translate("job.apikey.deleting.title")}>
      <CircularProgress size={24} />
    </Tooltip>
  ) : (
    <ButtonMenu id={`job-menu-${props.jobId}`} small items={items} onClose={props.onClose} />
  )
}
