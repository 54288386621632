import { store } from "app/store/store"
import { JServerService, JServerVersion } from "./model"
import { checkServerReady, isServerReady } from "./tools/common"

export const serverSVC: JServerService = {
  isReady: isServerReady,
  getVersion(): JServerVersion {
    checkServerReady()
    return store.getState().server.version
  }
}
