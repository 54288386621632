import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, IconButton, ListItemText, Menu, MenuItem, useTheme } from "@mui/material"
import { GridSelectionModel } from "@mui/x-data-grid-pro"
import React from "react"
import { JButtonMenuItem } from "./ButtonMenu"

export interface JButtonMenuProps {
  id: string
  selectedIds: GridSelectionModel
  disabled?: boolean
  items: JButtonMenuItem[]
  small?: boolean
  onClose?: () => void
  onClick?: () => void
}

export const ButtonMenuSelected = (props: JButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const handleClose = () => {
    setAnchorEl(null)
    props.onClose?.()
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id={props.id}
        aria-controls={open ? `${props.id}-menu` : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
          props.onClick?.()
        }}
      >
        <FontAwesomeIcon icon={regular("ellipsis-vertical")} size={props.small ? "sm" : "lg"} style={{ aspectRatio: "1 / 1" }} />
      </IconButton>
      <Menu
        sx={{
          "& .MuiMenu-list": {
            paddingTop: 0
          },
          "& .MuiPaper-root": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px"
          }
        }}
        id={`${props.id}-menu`}
        MenuListProps={{
          "aria-labelledby": props.id
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, opacity: "1 !important" }} disabled={true}>
          {`${props.selectedIds.length} items selected`}
        </MenuItem>
        {props.items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
            disabled={props.disabled || item.disabled || item.isLoading}
          >
            <ListItemText>{item.title}</ListItemText>
            {item.isLoading && <CircularProgress size={16} />}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
