import { ACTIONS } from "app/store/actions"
import { JPageState } from "page/model"
import { Action, Reducer } from "redux"
import { JActionActivatePage, JActionSetPages } from "./actions"
import { getDefaultPageState } from "./state"

export const pageReducer: Reducer<JPageState, Action> = (state: JPageState | undefined, action: Action): JPageState => {
  if (!state) {
    state = getDefaultPageState()
  }
  switch (action.type) {
    case ACTIONS.PAGE_ACTIVATE: {
      const { page } = action as JActionActivatePage
      return { ...state, activePage: page }
    }

    case ACTIONS.PAGE_SET_ALL: {
      const { pages } = action as JActionSetPages
      return { ...state, pages, activePage: pages.find(p => p.jsxElement === state?.activePage.jsxElement) }
    }

    default:
      return state
  }
}
