import { translate } from "app/language/service"
import { memberEventModule } from "member/event"
import { JMember, JMemberEventDeleteParams, JMemberUpdateParams, MEMBER_EVENTS } from "member/model"
import memberRPO from "member/repository"
import { messageSVC } from "message/service"
import { ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"

export function getAllMembers(organizationId: string): Promise<JMember[]> {
  return memberRPO.getAllMembers(organizationId)
}

// Watch out this call is very inefficient and does not scale well for the moment, because
// the number of network requests being performed is proportional to the member list size
export async function getMembersAndRoles() {
  const members = await getAllMembers(getUserOrganization().id)
  return Promise.all(
    members.map(async member => {
      const roles = (await memberRPO.getById(member.id, getUserOrganization().id)).roles
      return { ...member, roles }
    })
  )
}

export async function updateMember(memberId: string, organizationId: string, params: JMemberUpdateParams): Promise<void> {
  if (typeof memberId !== "string" || !memberId) {
    return Promise.reject("memberId must be a non empty string")
  }
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (!Array.isArray(params.roles)) {
    return Promise.reject("params.roles must be an array")
  }
  try {
    await memberRPO.update(memberId, organizationId, params)
    messageSVC.success(translate("member.update.success", { memberId }))
  } catch (error) {
    return Promise.reject(error)
  }
}

export function deleteMember(memberId: string, organizationId: string, transferredOwnerId?: string): Promise<void> {
  if (typeof memberId !== "string" || !memberId) {
    return Promise.reject("memberId must be a non empty string")
  }
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  return new Promise<void>((resolve, reject) => {
    memberRPO
      .delete(memberId, organizationId, transferredOwnerId)
      .then(() => {
        messageSVC.success(translate("member.delete.success"))
        memberEventModule.notify(MEMBER_EVENTS.DELETE, { memberId } as JMemberEventDeleteParams)
        resolve()
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
  })
}

export function getFormattedRole(role: ROLES): string {
  return translate(`user.role.${role}`)
}
