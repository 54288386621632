import { EventModule } from "app/event/EventModule"
import { JServerEventModule, JServerInfo, JSERVER_EVENTS } from "./model"

class ServerEventModule extends EventModule implements JServerEventModule {
  public on = {
    infoReady: (listenerId: string, fn: (serverInfo: JServerInfo) => void): void => {
      this.addListenerFn(JSERVER_EVENTS.INFO_READY, listenerId, fn)
    }
  }
}

export const serverEventModule: ServerEventModule = new ServerEventModule()
