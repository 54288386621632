import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { languageSVC, translate } from "app/language/service"
import { dateSVC } from "app/tool/date/service"
import { JFileInfo } from "file/model"
import { getFileTypeLabel, getStatusChipFromFileStatus } from "file/utils"
import { filesize } from "filesize"
import React from "react"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { TextLabel } from "ui/components/TextLabel"

export const FileInfoDialog = ({ fileInfo, onClose }: { fileInfo: JFileInfo; onClose: () => void }) => (
  <Dialog open fullWidth maxWidth="lg">
    <DialogTitle sx={{ m: 0, p: 2 }}>{translate("sds.file.info.dialog.title")}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextLabel value={fileInfo.filename} label={translate("sds.file.info.name")} />
            </Grid>
            <Grid item xs={4}>
              <TextLabel label={translate("sds.file.info.size")} value={filesize(fileInfo.fileSize, { locale: languageSVC.getLocale() })} />
            </Grid>
            <Grid item xs={4}>
              <TextLabel label={translate("sds.file.info.type")} value={getFileTypeLabel(fileInfo)} />
            </Grid>
            <Grid item xs={4}>
              <RichTextLabel label={translate("sds.file.info.status")} value={getStatusChipFromFileStatus(fileInfo.status)} />
            </Grid>
            <Grid item xs={12}>
              <TextLabel label={translate("sds.file.info.uploadedBy")} value={fileInfo.createdBy} />
            </Grid>
            <Grid item xs={12}>
              <TextLabel label={translate("sds.file.info.uploadedOn")} value={dateSVC.format(fileInfo.creationDate, { displayTime: true, longFormat: true })} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextLabel label={translate("sds.file.info.metadata")} fullWidth multiline monospace rows={15} value={JSON.stringify(fileInfo, null, 2)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{translate("button.close")}</Button>
    </DialogActions>
  </Dialog>
)
