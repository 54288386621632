import { Box, CircularProgress, Paper, Typography } from "@mui/material"
import { languageSVC, translate } from "app/language/service"
import { filesize } from "filesize"
import React, { ReactElement } from "react"

interface JDashboardUsageItemProps {
  icon: ReactElement
  title: string
  usageInBytesTranslationKey: string
  getUsageInBytes: () => Promise<number>
}

export const DashboardUsageItem = (props: JDashboardUsageItemProps): JSX.Element => {
  const [isCounting, setIsCounting] = React.useState(true)
  const [usage, setUsage] = React.useState("")
  React.useEffect(() => {
    if (!isCounting) {
      return
    }
    props
      .getUsageInBytes()
      .then(usageInBytes => {
        setUsage(
          translate(props.usageInBytesTranslationKey, {
            bytes: filesize(usageInBytes, { locale: languageSVC.getLocale() })
          })
        )
        setIsCounting(false)
      })
      .catch(error => {
        console.error(error)
        setIsCounting(false)
      })
  }, [usage])
  return (
    <Paper
      sx={theme => ({
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "padding": "1.2rem",
        "& .dashboard-icon": {
          color: theme.palette.secondary.main,
          width: "2.5rem",
          height: "2.5rem"
        }
      })}
    >
      {props.icon}
      <Typography color="textPrimary" sx={{ marginTop: "1rem", marginBottom: ".2rem", fontSize: "1.125rem", fontWeight: "bold" }}>
        {props.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%"
        }}
      >
        {isCounting ? (
          <CircularProgress size={24} sx={{ marginTop: "1rem", marginBottom: ".2rem" }} />
        ) : (
          <Typography color="textPrimary" sx={{ fontSize: "1rem" }}>
            {usage}
          </Typography>
        )}
      </Box>
    </Paper>
  )
}
