import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { JProject } from "jmapcloud-types"
import { gridStateReducers } from "ui/tools/grid"
import { JProjectState } from "./model"

const initialProjectState: JProjectState = {
  displayCards: true, // card view for the moment
  // filter: "",
  // listSort: ALL_LIST_SORT_DIRECTIONS[0],
  isFormDialogOpen: false,
  savedProject: null, // if savedProject=null and isFormDialogOpen=true the popup is for project creation
  projectForWhichToEditPermissions: null,
  projectForWhichToManageMvtCache: null,

  page: 0,
  pageSize: 100,
  totalRowCount: 0,
  sortModel: [{ field: "name", sort: "asc" }],
  filterModel: { items: [] },
  isLoading: false,
  quickFilter: "",
  reloadCounter: 0
}

const projectSlice = createSlice({
  name: "project",
  initialState: initialProjectState,
  reducers: {
    ...{
      // setFilter(state: JProjectState, action: PayloadAction<string>) {
      //   state.filter = action.payload
      // },
      setDisplayCards(state: JProjectState, action: PayloadAction<boolean>) {
        state.displayCards = action.payload
      },
      // setListSort(state: JProjectState, action: PayloadAction<LIST_SORT_DIRECTIONS>) {
      //   state.listSort = action.payload
      // },
      setFormDialog(state: JProjectState, action: PayloadAction<{ isOpen: boolean; project?: JProject }>) {
        state.isFormDialogOpen = action.payload.isOpen
        state.savedProject = action.payload.project || null
      },
      setProjectPermissionDialog(state: JProjectState, action: PayloadAction<JProject | null>) {
        state.projectForWhichToEditPermissions = action.payload
      },
      setProjectMvtCacheDialog(state: JProjectState, action: PayloadAction<JProject | null>) {
        state.projectForWhichToManageMvtCache = action.payload
      },
      setQuickFilter(state: JProjectState, action: PayloadAction<string>) {
        state.quickFilter = action.payload
      }
    },
    ...gridStateReducers
  }
})

export const {
  setDisplayCards,
  setFormDialog,
  setProjectPermissionDialog,
  setProjectMvtCacheDialog,

  setPage,
  setPageSize,
  setTotalRowCount,
  setSortModel,
  setFilterModel,
  setQuickFilter,
  reload
} = projectSlice.actions

export default projectSlice.reducer
