import { translate } from "app/language/service"
import { messageSVC } from "message/service"

export function copyToClipboard(text: string): void {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }
  navigator.clipboard
    .writeText(text)
    .then(() => messageSVC.info(translate("label.copied.in.clipboard")))
    .catch(err => {
      console.error("Async: Could not copy text: ", err)
    })
}

// Old browser clipboard copy
function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea")
  textArea.value = text
  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    if (!document.execCommand("copy")) {
      console.error("Fallback: Copying text command was unsuccessful")
    }
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err)
  }
  document.body.removeChild(textArea)
  messageSVC.success(translate("label.copied.in.clipboard"))
}
