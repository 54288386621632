import { JTag } from "organization/model"
import { getAllTags } from "organization/tools/common"
import React from "react"

export function useTags() {
  const [tags, setTags] = React.useState<JTag[]>([])

  React.useEffect(() => {
    // Idea for this taken here: https://stackoverflow.com/a/60907638/787842
    // Not sure how it works exactly but it's needed because of the use of the `key` prop to reset the
    // state of the forms where this custom hook is used
    let isMounted = true
    getAllTags()
      .then(allTags => {
        if (isMounted) {
          setTags(allTags.sortByProperty("name"))
        }
      })
      .catch(error => {
        console.error(error)
      })
    return () => {
      isMounted = false
    }
  }, [])

  return tags
}
