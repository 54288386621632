import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { JAppConfirmDialogProps, JAppErrorDialogProps, JAppMessage, JAppMessageState, JAppOverlayMessage } from "./model"

const MAX_MESSAGE_QUEUE_LENGTH = 740

const initialMessageState: JAppMessageState = {
  messages: [],
  overlayMessages: [],
  confirmDialogProps: null,
  errorDialogProps: null
}

const messageSlice = createSlice({
  name: "message",
  initialState: initialMessageState,
  reducers: {
    addMessage(state: JAppMessageState, action: PayloadAction<JAppMessage>) {
      const message = action.payload
      const newMessages = [...state.messages, message]
      while (newMessages.length > MAX_MESSAGE_QUEUE_LENGTH) {
        newMessages.shift()
      }
      state.messages = newMessages
    },
    setMessageExpired(state: JAppMessageState, action: PayloadAction<string>) {
      const messageId = action.payload
      const newMessages = state.messages.map(msg => (msg.id !== messageId ? msg : { ...msg, expired: true }))
      state.messages = newMessages
    },
    addOverlayMessage(state: JAppMessageState, action: PayloadAction<JAppOverlayMessage>) {
      state.overlayMessages.push(action.payload)
    },
    deleteOverlayMessages(state: JAppMessageState, action: PayloadAction<string[]>) {
      const messageIds = action.payload
      state.overlayMessages = state.overlayMessages.filter(message => !messageIds.includes(message.id))
    },
    setConfirmDialogProps(state: JAppMessageState, action: PayloadAction<JAppConfirmDialogProps | null>) {
      state.confirmDialogProps = action.payload
    },
    setErrorDialogProps(state: JAppMessageState, action: PayloadAction<JAppErrorDialogProps | null>) {
      state.errorDialogProps = action.payload
    }
  }
})

export const { addMessage, setMessageExpired, addOverlayMessage, deleteOverlayMessages, setConfirmDialogProps, setErrorDialogProps } = messageSlice.actions

export default messageSlice.reducer
