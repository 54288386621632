import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Dialog, DialogContent, DialogTitle, Tab, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { translate } from "app/language/service"
import { JProject } from "jmapcloud-types"
import React from "react"
import { ProjectMvtTileCacheDialogStatus } from "./ProjectMvtTileCacheDialogState"
import { ProjectMvtTileCacheForm } from "./ProjectMvtTileCacheForm"

interface JProjectMvtTileCacheDialogProps {
  project: JProject
}

export const ProjectMvtTileCacheDialog = (props: JProjectMvtTileCacheDialogProps) => {
  const [activeTab, setActiveTab] = React.useState("new")

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }
  return (
    <Dialog open={props.project !== null} fullWidth maxWidth="md">
      <DialogTitle>
        {translate("mvt.cache.dialog.title")}
        <Typography variant="subtitle1" component="div">
          {props.project.name[props.project.defaultLanguage]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              sx={{
                "& button": {
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: theme => theme.palette.text.primary
                },
                "& button.Mui-selected": {
                  color: theme => theme.palette.primary.main
                }
              }}
              onChange={handleTabChange}
            >
              <Tab label={translate("mvt.cache.dialog.new")} value={"new"} />
              <Tab label={translate("mvt.cache.dialog.state")} value={"state"} />
            </TabList>
          </Box>

          <TabPanel value="new" sx={{ padding: 0 }}>
            <ProjectMvtTileCacheForm project={props.project} />
          </TabPanel>
          <TabPanel value="state" sx={{ padding: 0 }}>
            <ProjectMvtTileCacheDialogStatus projectId={props.project.id} />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}
