import { EventModule } from "app/event/EventModule"
import { JUserEventLoginParams, JUserEventModule, USER_EVENTS } from "./model"

class UserEventModule extends EventModule implements JUserEventModule {
  public on = {
    login: (listenerId: string, fn: (params: JUserEventLoginParams) => void): void => {
      this.addListenerFn(USER_EVENTS.LOGIN, listenerId, fn)
    },
    logout: (listenerId: string, fn: () => void): void => {
      this.addListenerFn(USER_EVENTS.LOGOUT, listenerId, fn)
    }
  }
}

export const userEventModule: UserEventModule = new UserEventModule()
