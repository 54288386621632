import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { JExtension } from "organization/model"
import { activateOrganisationExtension, deactivateOrganisationExtension, getExtensions, getOrganizationExtensions } from "organization/tools/common"
import { naturalSortString } from "project/utils"
import React from "react"
import { OrganizationExtensionCard } from "./OrganizationExtensionCard"

export const OrganizationExtension = () => {
  const [extensions, setExtensions] = React.useState<JExtension[]>([])
  const [organisationExtensions, setOrganisationExtensions] = React.useState<string[]>([])
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.tab.extension.loading.error"))
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const fetchExtensions = async () => {
    setIsLoading(true)
    resetError()
    try {
      const [fetchedExtensions, fetchedOrganisationExtensions] = await Promise.all([getExtensions(), getOrganizationExtensions()])
      setExtensions(fetchedExtensions.sort((a, b) => naturalSortString(a.name, b.name)))
      setOrganisationExtensions(fetchedOrganisationExtensions.map(ex => ex.extensionId))
    } catch (err: any) {
      console.error(err)
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }
  React.useEffect(() => {
    fetchExtensions()
  }, [])

  const updateExtension = async (isActivated: boolean, extensionId: string) => {
    setIsLoading(true)
    resetError()
    try {
      if (isActivated) {
        await activateOrganisationExtension(extensionId)
      } else {
        await deactivateOrganisationExtension(extensionId)
      }
      await fetchExtensions()
    } catch (error: any) {
      console.error(error)
      handleError(error, translate("organization.tab.extension.updating.error"))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ height: 4 }}>{isLoading && <LinearProgress />}</Box>
      {hasError && (
        <Typography color="error" padding={"1rem 0"}>
          {errorMessage}
        </Typography>
      )}
      <Stack gap={3} padding={"1rem 0"}>
        {extensions.length === 0 && !isLoading && (
          <Typography color="textPrimary" padding={"1rem 0"}>
            {translate("organization.tab.extension.none")}
          </Typography>
        )}
        {extensions.map(extension => (
          <OrganizationExtensionCard key={extension.id} extension={extension} isActivated={organisationExtensions.includes(extension.id)} disabled={isLoading} toggleActivation={updateExtension} />
        ))}
      </Stack>
    </>
  )
}
