import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, CircularProgress, Dialog, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import React from "react"
import { setSwitchOrganisationDialogIsOpen } from "user/store"
import { logIntoOrganization, logout } from "user/tools/common"

export function OrganizationSelectDialog() {
  const [loadingOrganizationId, setLoadingOrganizationId] = React.useState("")
  const dispatch = useAppDispatch()
  const userState = useAppSelector(state => state.user)

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={userState.isUserLoggedIn && (userState.switchOrganisationDialogIsOpen || !userState.selectedOrganization) && userState.organizations.length > 0}
      onClose={() => dispatch(setSwitchOrganisationDialogIsOpen(false))}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "16rem"
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.3rem",
            fontWeight: "bold",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
          color="textPrimary"
        >
          {translate("login.choose.organization.title")}
        </Typography>

        <Typography
          color="error"
          sx={{
            borderTop: "1rem",
            borderBottom: "1rem",
            textAlign: "center"
          }}
        >
          {userState.organizationLoginError}
        </Typography>

        <Box
          sx={{
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "width": "70%",
            "maxWidth": "70%",
            "& .MuiInputBase-input": {
              paddingLeft: ".5rem",
              paddingRight: ".5rem"
            }
          }}
          className="jmap-main-panel-login-box"
          // bgcolor="background.paper"
          padding="1rem"
          alignItems="center"
          justifyContent="stretch"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              maxHeight: "16rem",
              overflowX: "hidden",
              overflowY: "auto"
            }}
          >
            {userState.organizations.map(organisation => (
              <Button
                sx={{
                  justifyContent: "flex-start",
                  marginLeft: "0.25rem",
                  marginRight: "0.25rem",
                  marginBottom: "0.5rem",
                  width: "16rem",
                  maxWidth: "16rem",
                  minWidth: "16rem"
                }}
                startIcon={
                  userState.isLoggingIn && organisation.id === loadingOrganizationId ? (
                    <CircularProgress size="1rem" sx={{ color: "#ffffff" }} />
                  ) : (
                    <FontAwesomeIcon icon={solid("sign-in")} size="sm" />
                  )
                }
                key={`org-${organisation.id}`}
                disabled={userState.isLoggingIn}
                color="primary"
                onClick={() => {
                  setLoadingOrganizationId(organisation.id)
                  logIntoOrganization(organisation)
                }}
              >
                {organisation.name}
              </Button>
            ))}
            <Button
              sx={{
                justifyContent: "flex-start",
                marginLeft: "0.25rem",
                marginRight: "0.25rem",
                marginBottom: "0.5rem",
                width: "16rem",
                maxWidth: "16rem",
                minWidth: "16rem",
                marginTop: "1rem",
                backgroundColor: theme => theme.palette.error.main
              }}
              startIcon={<FontAwesomeIcon icon={solid("sign-out")} size="sm" />}
              disabled={userState.isLoggingIn}
              key={"org-logout"}
              color="warning"
              onClick={async () => await logout()}
            >
              {"Logout"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
