import { Box } from "@mui/material"
import { JRootState } from "app/model"
import { JAppConfirmDialogProps, JAppErrorDialogProps, JAppMessage, MESSAGE_WIDTH_IN_REM } from "message/model"
import React from "react"
import { connect } from "react-redux"
import { AppMessage } from "./AppMessage"
import { ConfirmDialog } from "./ConfirmDialog"
import { ErrorDialog } from "./ErrorDialog"
import { OverlayMessages } from "./OverlayMessages"

export interface JMessageCenterProps extends JMessageCenterConnectProps {
  maxSize?: boolean
}

export interface JMessageCenterConnectProps {
  messages: JAppMessage[]
  confirmDialogProps: JAppConfirmDialogProps | undefined
  errorDialogProps: JAppErrorDialogProps | undefined
}

const MessageCenterFn = (props: JMessageCenterProps) => (
  <>
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column-reverse",
        alignContent: "center",
        pointerEvents: "none",
        left: 0,
        bottom: 0,
        width: props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM,
        minWidth: props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM,
        maxWidth: props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM,
        height: "12rem",
        minHeight: "12rem"
      }}
    >
      {props.confirmDialogProps && <ConfirmDialog {...props.confirmDialogProps} />}
      {props.errorDialogProps && <ErrorDialog {...props.errorDialogProps} />}
      {props.messages
        .filter(m => !m.expired)
        .map(messg => (
          <AppMessage key={messg.id} message={messg} maxSize={props.maxSize} />
        ))}
    </Box>
    <OverlayMessages />
  </>
)

export const MessageCenter = connect(
  (state: JRootState) =>
    ({
      messages: state.message.messages,
      confirmDialogProps: state.message.confirmDialogProps,
      errorDialogProps: state.message.errorDialogProps
    } as JMessageCenterConnectProps)
)(MessageCenterFn)
