import { createAuth0Client, IdToken, User } from "@auth0/auth0-spa-js"
import { store } from "app/store/store"
import { jwtDecode, JwtPayload } from "jwt-decode"
import { JSERVER_IDENTITY_PROVIDER_TYPES, JServerIdentityProviderAuth0Spa } from "server/model"
import { userCFG } from "user/config"
import { JPasswordPolicyCompliance, JTokenInfo, PASSWORD_ACCEPTED_SPECIAL_CHARACTERS, PASSWORD_MIN_LENGTH } from "user/model"
import { refreshAccessTokens } from "user/store"

function getAuth0SpaIdentityProvider(): JServerIdentityProviderAuth0Spa {
  const idps = store.getState().server.identityProviders.filter(idp => idp.type === JSERVER_IDENTITY_PROVIDER_TYPES.AUTH0_SPA)
  if (idps.length === 0) {
    throw Error("Could not find an Auth0-SPA identity provider")
  } else if (idps.length > 1) {
    throw Error("Too many Auth0-SPA identify providers")
  }
  return idps[0] as JServerIdentityProviderAuth0Spa
}

export async function initAuth0Client(organizationAuth0Id?: string): Promise<void> {
  const identityProvider = getAuth0SpaIdentityProvider()

  userCFG.auth0Client = await createAuth0Client({
    domain: identityProvider.domain,
    clientId: identityProvider.clientId,
    cacheLocation: "localstorage", // store refresh token in local storage over memory
    useRefreshTokens: true, // use refresh token rotation
    // Watch out!! Setting this param was needed to make the V2 upgrade of auth0-spa-js work
    // see: https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
    useRefreshTokensFallback: true,
    authorizationParams: {
      audience: identityProvider.audience,
      organization: organizationAuth0Id,
      redirect_uri: `${location.origin}/`
    }
  })
}

export function keepTokenValid(delayInMSecs: number): void {
  userCFG.sessionKeeperTimeout = setTimeout(async () => {
    try {
      const newAccessToken = await userCFG.auth0Client.getTokenSilently({ cacheMode: "off" })
      store.dispatch(refreshAccessTokens(newAccessToken))
      const callbackAtTokenRefresh = store.getState().user.callbackAtTokenRefresh
      if (callbackAtTokenRefresh) {
        callbackAtTokenRefresh()
      }
      keepTokenValid(delayInMSecs) // @recursivity
    } catch (ex) {
      console.warn("Cannot keep token alive", ex)
      const identityProvider = getAuth0SpaIdentityProvider()
      await userCFG.auth0Client.logout({
        logoutParams: {
          returnTo: identityProvider.logoutRedirectUrl
        }
      })
      return
    }
  }, delayInMSecs) as any
}

export async function getTokenInfo(): Promise<JTokenInfo | undefined> {
  let accessToken: string | undefined
  let currentUser: User | undefined
  let idToken: IdToken | undefined
  try {
    // generate an access token from the refresh token stored in the local storage
    accessToken = await userCFG.auth0Client.getTokenSilently({ cacheMode: "off" })
    currentUser = await userCFG.auth0Client.getUser()
    idToken = await userCFG.auth0Client.getIdTokenClaims()
  } catch (error) {
    return
    // nothing to do
  }

  if (!accessToken || !currentUser || !idToken) {
    return
  }

  const claims = await userCFG.auth0Client.getIdTokenClaims()

  const accessTokenDecoded = jwtDecode<JwtPayload>(accessToken)

  let accessTokenExpirationInMSecs: number = 60 * 2 * 1000 // default: 2 mins

  if (accessTokenDecoded.exp) {
    accessTokenExpirationInMSecs = new Date(accessTokenDecoded.exp * 1000).getTime() - new Date().getTime()
    if (accessTokenExpirationInMSecs < 0) {
      console.warn("Access token is expired, will redirect to login")
      await auth0Login()
      return undefined as any // will redirect no importance
    }
  } else {
    console.error("Unable to read expiration from access token")
  }
  return {
    accessToken,
    idToken,
    accessTokenExpirationInMSecs,
    picture: claims?.picture || ""
  }
}

export async function clearAuth0Session() {
  await userCFG.auth0Client.logout({ openUrl: false })
}

export async function auth0Login(): Promise<void> {
  await userCFG.auth0Client.loginWithRedirect({
    authorizationParams: {
      redirect_uri: `${location.origin}/`
    }
  })
}

export async function revokeRefreshToken(localOnly: boolean): Promise<void> {
  const identityProvider = getAuth0SpaIdentityProvider()

  await userCFG.auth0Client.logout({
    logoutParams: {
      returnTo: identityProvider.logoutRedirectUrl,
      openUrl: !localOnly
    }
  })
}

export function isPasswordCompliant(password: string): boolean {
  return Object.values(getPasswordPolicyCompliance(password)).every(Boolean)
}

export function getPasswordPolicyCompliance(password: string): JPasswordPolicyCompliance {
  return {
    hasMinimumLength: password.length >= PASSWORD_MIN_LENGTH,
    hasLowercaseLetters: /[a-z]/.test(password),
    hasNumbers: /[0-9]/.test(password),
    hasUppercaseLetters: /[A-Z]/.test(password),
    hasSpecialCharacters: new RegExp(`[${PASSWORD_ACCEPTED_SPECIAL_CHARACTERS}]`).test(password)
  }
}
