import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, CircularProgress, IconButton, Paper, Typography } from "@mui/material"
import { translate } from "app/language/service"
import React, { ReactElement } from "react"

interface JDashboardEntityItemProps {
  icon: ReactElement
  titleTranslationKey: string
  addLabel?: string
  onAdd?: () => any
  getCount: () => Promise<number>
  onTitleClick?: () => void
}

export const DashboardEntityItem = (props: JDashboardEntityItemProps): JSX.Element => {
  const [isCounting, setIsCounting] = React.useState(true)
  const [count, setCount] = React.useState(-1)
  const [title, setTitle] = React.useState("")
  React.useEffect(() => {
    if (!isCounting || count !== -1) {
      return
    }
    props
      .getCount()
      .then(newCount => {
        setTitle(translate(props.titleTranslationKey, { numOfItems: newCount }))
        setCount(newCount)
        setIsCounting(false)
      })
      .catch(error => {
        console.error(error)
        setCount(0)
        setIsCounting(false)
      })
  })
  return (
    <Paper
      sx={theme => ({
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "padding": "1.2rem",
        "& .dashboard-icon": {
          color: theme.palette.secondary.main,
          width: "2.5rem",
          height: "2.5rem"
        },
        "& .dashboard-add-icon": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
          padding: "0.4rem",
          width: ".8rem",
          height: ".8rem",
          borderRadius: "0.4rem"
        }
      })}
    >
      {props.icon}
      {isCounting ? (
        <CircularProgress size={24} sx={{ marginTop: "1rem", marginBottom: ".2rem" }} />
      ) : (
        <Typography onClick={props.onTitleClick} color="textPrimary" sx={{ marginTop: "1rem", marginBottom: ".2rem", fontSize: "1.125rem", fontWeight: "bold", cursor: "pointer" }}>
          {title}
        </Typography>
      )}
      {props.addLabel && props.onAdd && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginLeft: "-.5rem"
          }}
        >
          <IconButton aria-label="add" onClick={() => props.onAdd!()}>
            <FontAwesomeIcon className="dashboard-add-icon" icon={solid("plus")} size="lg" />
          </IconButton>
          <Typography color="textPrimary" sx={{ fontSize: "1rem" }}>
            {props.addLabel}
          </Typography>
        </Box>
      )}
    </Paper>
  )
}
