import { Box } from "@mui/material"
import Color from "color"
import React from "react"

export interface JColorSwatchProps {
  color: Color
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any
}

export const ColorSwatch = (props: JColorSwatchProps): JSX.Element => (
  <Box
    sx={{
      position: "relative",
      width: "1.875rem",
      minWidth: "1.875rem",
      maxWidth: "1.875rem",
      height: "1.875rem",
      minHeight: "1.875rem",
      maxHeight: "1.875rem",
      borderRadius: "0.5rem",
      cursor: props.onClick ? "pointer" : "normal"
    }}
    onClick={event => {
      if (props.onClick) {
        props.onClick(event)
      }
    }}
  >
    <Box
      className="swatchBackround"
      sx={{
        top: 0,
        left: 0,
        position: "absolute",
        background:
          /* eslint-disable max-len */
          'white url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',
        borderRadius: "0.5rem",
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%"
      }}
    />
    <Box
      className="swatchColor"
      sx={{
        top: 0,
        left: 0,
        position: "absolute",
        borderRadius: "0.5rem",
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        backgroundColor: props.color.hex(),
        opacity: props.color.alpha(),
        border: theme => `1px solid ${theme.palette.text.secondary}`
      }}
    />
  </Box>
)
