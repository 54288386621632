import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Button, IconButton, Menu, MenuItem, Stack } from "@mui/material"
import { GridSelectionModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { FileSelectDialog } from "file/components/FileSelectDialog"
import { JFileInfo } from "file/model"
import React from "react"
import { DATA_SOURCE_PERMISSIONS, JDataSource } from "spatialdatasource/model"
import { reload, setSdsToUpdate } from "spatialdatasource/store"
import { getUserDataSourcePermissions } from "spatialdatasource/utils"
import { QuickFilterInput } from "ui/components/QuickFilterInput"
import { DataSourceDetailsDialog } from "./DataSourceDetailsDialog"
import { DataSourceGrid } from "./DataSourceGrid"
import { WmsWmtsDataSourceFormDialog } from "./WmsWmtsDataSourceFormDialog"

type JCreateDataSourceDialog = "FILE" | "WMS/WMTS" | null

export const CreateButtonMenu = (props: { onClick: (dialog: JCreateDataSourceDialog) => void }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{ minWidth: "10rem" }}
      >
        {translate("sds.create.title")}
      </Button>
      <Menu PaperProps={{ sx: { width: anchorEl?.offsetWidth } }} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            props.onClick("FILE")
            handleClose()
          }}
        >
          {translate("sds.create.button.menu.data.file")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onClick("WMS/WMTS")
            handleClose()
          }}
        >
          {translate("sds.create.button.menu.wms.wmst.service")}
        </MenuItem>
        <MenuItem disabled>{translate("sds.create.button.menu.vector.tile.service")}</MenuItem>
        <MenuItem disabled>{translate("sds.create.button.menu.feature.service")}</MenuItem>
      </Menu>
    </>
  )
}

const dataSourcesPermissionsMap = new Map<string, string[]>()

export const DataSourcePanel = (props: { setCurrentFileInfos: (fileInfos: JFileInfo[]) => void }) => {
  const [openDialog, setOpenDialog] = React.useState<JCreateDataSourceDialog>(null)
  const [dataSourceToDisplay, setDataSourceToDisplay] = React.useState<JDataSource | null>(null)
  const [selectedDataSourceIds, setSelectedDataSourceIds] = React.useState<GridSelectionModel>([])
  const [quickFilter, setQuickFilter] = React.useState<string>("")
  const [selectedDataSourcesIsOwner, setSelectedDataSourcesIsOwner] = React.useState<boolean>(false)
  const [selectedDataSourcesIsOwnerLoading, setSelectedDataSourcesIsOwnerLoading] = React.useState<boolean>(false)

  const sdsToUpdate = useAppSelector(state => state.sds.sdsToUpdate)

  const dispatch = useAppDispatch()

  const buildIsOwnerArray = React.useCallback(async (): Promise<boolean[]> => {
    const isOwnerArray: boolean[] = await Promise.all(
      selectedDataSourceIds.map(async id => {
        if (!dataSourcesPermissionsMap.has(id as string)) {
          const permissions = await getUserDataSourcePermissions(id as string)
          dataSourcesPermissionsMap.set(id as string, permissions)
          return permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER)
        } else {
          return dataSourcesPermissionsMap.get(id as string)!.includes(DATA_SOURCE_PERMISSIONS.OWNER)
        }
      })
    )
    setSelectedDataSourcesIsOwnerLoading(false)
    return isOwnerArray
  }, [selectedDataSourceIds])

  React.useEffect(() => {
    const checkIfAllDataSourcesAreOwners = async () => {
      const isOwnerArray = await buildIsOwnerArray()
      const allDataSourcesAreOwners = isOwnerArray.every(isOwner => isOwner === true)
      setSelectedDataSourcesIsOwner(allDataSourcesAreOwners)
    }
    setSelectedDataSourcesIsOwnerLoading(true)
    checkIfAllDataSourcesAreOwners()
  }, [selectedDataSourceIds, buildIsOwnerArray])

  return (
    // Main vertical container
    <Stack spacing={2} height="100%">
      {/* Top horizontal container, below tabs, with widgets on left and create button on right */}
      <Stack direction="row" alignItems={"center"} justifyContent="space-between">
        {/* Widgets on left */}
        <Stack direction="row" spacing={2} alignItems="center" paddingLeft={"10px"}>
          <IconButton sx={{ marginLeft: "0.70rem" }} color="primary" size="small" onClick={() => dispatch(reload())}>
            <FontAwesomeIcon icon={light("refresh")} />
          </IconButton>
          <QuickFilterInput value={quickFilter} onChange={setQuickFilter} />
        </Stack>
        {/* Create button on right */}
        <CreateButtonMenu onClick={dialog => setOpenDialog(dialog)} />
      </Stack>

      {/* Note: controlling the presence of the dialog this way, instead of using its `open` props,
                dispenses from having to manage the reset of its state, when reopening it */}
      {(openDialog === "FILE" || sdsToUpdate !== null) && (
        <FileSelectDialog
          onFilesReady={fileInfos => {
            props.setCurrentFileInfos(fileInfos)
            setOpenDialog(null)
          }}
          close={() => {
            setOpenDialog(null)
            dispatch(setSdsToUpdate(null))
          }}
        />
      )}

      {openDialog === "WMS/WMTS" && (
        <WmsWmtsDataSourceFormDialog
          close={() => {
            setOpenDialog(null)
          }}
          afterSubmit={() => {
            setOpenDialog(null)
            dispatch(reload()) // reload SDS grid
          }}
        />
      )}

      {dataSourceToDisplay && (
        <DataSourceDetailsDialog
          dataSource={dataSourceToDisplay}
          onClose={() => {
            setDataSourceToDisplay(null)
            dispatch(reload())
          }}
        />
      )}

      <DataSourceGrid {...{ setDataSourceToDisplay, selectedDataSourceIds, setSelectedDataSourceIds, selectedDataSourcesIsOwner, selectedDataSourcesIsOwnerLoading, quickFilter }} />
    </Stack>
  )
}
