import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material"
import { Box } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { FILE_INFO_STATUSES, FILE_INFO_TYPES, JFileInfo } from "file/model"
import { ACCEPTED_UPLOAD_MIME_TYPES, ALL_ACCEPTED_UPLOAD_MIME_TYPES, deleteFile, getAllUsableFileInfos, getFileInfo, uploadFile } from "file/utils"
import { messageSVC } from "message/service"
import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { DataSourcePanel } from "spatialdatasource/components/DataSourcePanel"
import { DATA_SOURCE_TYPES } from "spatialdatasource/model"
import * as tus from "tus-js-client"
import CircularProgressWithLabel from "ui/components/CircularProgressWithLabel"
import { dropzoneAcceptStyle, dropzoneActiveStyle, dropzoneRejectStyle } from "./FilePanel"

interface JFileSelectDialogProps {
  onFilesReady: (fileInfos: JFileInfo[]) => void
  close: () => void
}

// The stages for the upload tab
enum FILE_UPLOAD_STAGE {
  READY,
  UPLOADING,
  ANALYSIS
}

const SINGLE_FILE_UPLOAD_MAX_SIZE_IN_GB = 3
const FILE_ANALYSIS_STATUS_POLLING_INTERVAL = 3000

export const FileSelectDialog = (props: JFileSelectDialogProps) => {
  const [activeTab, setActiveTab] = useState<"Upload" | "Select">("Upload")
  const [fileUploadStage, setFileUploadStage] = useState<FILE_UPLOAD_STAGE>(FILE_UPLOAD_STAGE.READY)
  const [percentUploaded, setPercentUploaded] = useState(0)
  const [currentUpload, setCurrentUpload] = useState<tus.Upload | null>(null)
  const [allFileInfos, setAllFileInfos] = useState<JFileInfo[]>([])
  const [selectedFileInfos, setSelectedFileInfos] = useState<JFileInfo[]>([])

  const sdsToUpdate = useAppSelector(state => state.sds.sdsToUpdate)
  const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling(translate(sdsToUpdate === null ? "sds.create.error" : "sds.update.error"))

  const theme = useTheme()

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    noKeyboard: true,
    multiple: false,
    maxSize: SINGLE_FILE_UPLOAD_MAX_SIZE_IN_GB * 1e9, // in bytes
    accept:
      sdsToUpdate?.type === DATA_SOURCE_TYPES.FILE
        ? ACCEPTED_UPLOAD_MIME_TYPES.VECTORS
        : sdsToUpdate?.type === DATA_SOURCE_TYPES.RASTER
        ? ACCEPTED_UPLOAD_MIME_TYPES.RASTERS
        : ALL_ACCEPTED_UPLOAD_MIME_TYPES,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const fileToUpload = acceptedFiles[0]
        setFileUploadStage(FILE_UPLOAD_STAGE.UPLOADING)
        resetError()
        setPercentUploaded(0)
        const upload = uploadFile(fileToUpload, {
          onProgress: (bytesUploaded, bytesTotal) => {
            const perc = (bytesUploaded / bytesTotal) * 100
            setPercentUploaded(perc)
          },
          onSuccess: () => {
            const f = fileToUpload
            setFileUploadStage(FILE_UPLOAD_STAGE.ANALYSIS)
            const fileId = (upload.url as string).split("/").pop()!
            const intervalId = setInterval(() => {
              getFileInfo(fileId)
                .then(fileInfo => {
                  if (fileInfo.status === FILE_INFO_STATUSES.ANALYZED) {
                    clearInterval(intervalId)
                    setCurrentUpload(null)
                    setPercentUploaded(0)
                    // Special case where a raster is analyzed but still invalid (no CRS)
                    if (fileInfo.type === FILE_INFO_TYPES.RASTER_DATA && !fileInfo.metadata.crs) {
                      setErrorMessageAndActivateError(translate("sds.upload.file.error.no.crs"))
                      setFileUploadStage(FILE_UPLOAD_STAGE.READY)
                    } else {
                      /** SUCCESS! this callback is defined in {@link DataSourcePanel} */
                      props.onFilesReady([fileInfo])
                    }
                  } else if (fileInfo.status === FILE_INFO_STATUSES.ERROR) {
                    clearInterval(intervalId)
                    setErrorMessageAndActivateError(translate("sds.upload.file.analysis.error"))
                    setCurrentUpload(null)
                    setPercentUploaded(0)
                    setFileUploadStage(FILE_UPLOAD_STAGE.READY)
                  }
                  // another status -> no-op, continue polling the file info with the interval
                })
                .catch(error => {
                  clearInterval(intervalId)
                  handleError(error, translate("sds.upload.file.error"))
                  setCurrentUpload(null)
                  setPercentUploaded(0)
                  setFileUploadStage(FILE_UPLOAD_STAGE.READY)
                  console.error(error)
                })
            }, FILE_ANALYSIS_STATUS_POLLING_INTERVAL)
          },
          onError: (error: any) => {
            handleError(error, translate("sds.upload.file.error"))
            setCurrentUpload(null)
            setPercentUploaded(0)
            setFileUploadStage(FILE_UPLOAD_STAGE.READY)
            console.error(error)
          }
        })
        setCurrentUpload(upload)
      }
      fileRejections.forEach(fr => {
        fr.errors.forEach(err => {
          if (err.code === "too-many-files") {
            messageSVC.error(translate("sds.upload.file.reject.too.many.files.single"))
            messageSVC.info(translate("sds.upload.file.reject.too.many.files.single.info"))
          } else if (err.code === "file-invalid-type") {
            messageSVC.error(translate("sds.upload.file.reject.invalid.type", { fileType: fr.file.name }))
          } else if (err.code === "file-too-large") {
            messageSVC.error(
              translate("sds.upload.file.reject.too.large", {
                filename: fr.file.name,
                uploadMaxSize: SINGLE_FILE_UPLOAD_MAX_SIZE_IN_GB
              })
            )
            messageSVC.info(translate("sds.upload.file.reject.too.large.info"))
          }
        })
      })
    }
  })

  React.useEffect(() => {
    resetError()
    if (activeTab === "Select") {
      // Note: this has the effect that the files are reloaded every time the tab is selected
      getAllUsableFileInfos()
        .then(fileInfos => {
          if (!sdsToUpdate) {
            setAllFileInfos(fileInfos)
          } else if (sdsToUpdate.type === DATA_SOURCE_TYPES.FILE) {
            setAllFileInfos(fileInfos.filter(f => f.type === FILE_INFO_TYPES.VECTOR_DATA))
          } else if (sdsToUpdate.type === DATA_SOURCE_TYPES.RASTER) {
            setAllFileInfos(fileInfos.filter(f => f.type === FILE_INFO_TYPES.RASTER_DATA))
          } else {
            console.error(`An SDS of type ${sdsToUpdate.type} cannot be updated`)
          }
        })
        .catch(error => {
          handleError(error, translate("sds.upload.file.error"))
        })
    }
  }, [activeTab])

  const dropzoneStyle = React.useMemo(
    () => ({
      ...dropzoneActiveStyle,
      ...(isDragAccept ? { ...dropzoneActiveStyle, ...dropzoneAcceptStyle } : {}),
      ...(isDragReject ? { ...dropzoneActiveStyle, ...dropzoneRejectStyle } : {})
    }),
    [isDragAccept, isDragReject]
  )

  let uploadTabContent = <></>

  if (fileUploadStage === FILE_UPLOAD_STAGE.READY) {
    uploadTabContent = (
      <Box
        {...getRootProps({ style: dropzoneStyle })}
        sx={{
          width: "100%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <input hidden {...getInputProps()} />
        <Stack spacing={2}>
          <FontAwesomeIcon color={theme.palette.secondary.main} size="3x" icon={light("file-arrow-up")} />
          <Typography>{translate("sds.upload.dropzone.text")}</Typography>
        </Stack>
      </Box>
    )
  } else if (fileUploadStage === FILE_UPLOAD_STAGE.UPLOADING) {
    uploadTabContent = (
      <Stack spacing={2} alignItems="center">
        <CircularProgressWithLabel value={percentUploaded} />
        <Typography fontSize={20}>{translate("sds.uploading.file")}</Typography>
      </Stack>
    )
  } else if (fileUploadStage === FILE_UPLOAD_STAGE.ANALYSIS) {
    uploadTabContent = (
      <Stack spacing={2} alignItems="center">
        <CircularProgress size="5rem" />
        <Typography fontSize={20}>{translate("sds.analyzing.file")}</Typography>
      </Stack>
    )
  }

  const handleCancel = () => {
    if (currentUpload) {
      messageSVC.confirmDialog({
        // here we use yes/no because the main action is cancel!
        confirmButtonLabel: translate("button.yes"),
        cancelButtonLabel: translate("button.no"),
        isCancelDefault: true,
        title: translate("sds.upload.file.cancel"),
        message: translate("sds.upload.file.cancel.confirm"),
        onSuccess: () => {
          currentUpload.abort()
          setFileUploadStage(FILE_UPLOAD_STAGE.READY)
          const fileId = (currentUpload.url as string).split("/").pop()!
          deleteFile(fileId)
            .catch(error => {
              console.error("An error occured while trying to delete an aborted file transfer", error)
            })
            .finally(() => {
              props.close()
            })
        }
      })
    } else {
      props.close()
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, fileInfo: JFileInfo) => {
    if (fileInfo.type === FILE_INFO_TYPES.VECTOR_DATA) {
      // Can only select one
      setSelectedFileInfos(selectedFileInfos.includes(fileInfo) ? [] : [fileInfo])
    } else {
      // Can select multiple (but they need to be rasters)
      setSelectedFileInfos(
        selectedFileInfos.includes(fileInfo)
          ? [...selectedFileInfos.filter(f => f.id !== fileInfo.id && f.type === FILE_INFO_TYPES.RASTER_DATA)]
          : [...selectedFileInfos.filter(f => f.type === FILE_INFO_TYPES.RASTER_DATA), ...[fileInfo]]
      )
    }
  }

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>{translate(sdsToUpdate !== null ? "sds.update.from.file.title" : "sds.create.from.file.title")}</DialogTitle>

      <DialogContent>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(event, tab) => setActiveTab(tab)}>
              <Tab label={translate("sds.file.new")} value="Upload" />
              <Tab label={translate("sds.file.existing")} value="Select" />
            </TabList>
          </Box>

          {/* Upload new file */}
          <TabPanel value="Upload" sx={{ height: "100%", paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
            {uploadTabContent}
          </TabPanel>

          {/* Select existing file */}
          <TabPanel value="Select" sx={{ height: "100%", paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
            <TableContainer sx={{ height: "300px" }}>
              <Table padding="none" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell width="75%">{translate("label.name")}</TableCell>
                    <TableCell width="25%">{translate("label.type")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allFileInfos.map(fileInfo => (
                    <TableRow key={fileInfo.id} hover onClick={event => handleClick(event, fileInfo)} sx={{ "& td": { border: 0, cursor: "pointer" } }}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedFileInfos.indexOf(fileInfo) !== -1} />
                      </TableCell>
                      <TableCell>{fileInfo.filename}</TableCell>
                      <TableCell>{translate(`sds.file.type.${fileInfo.type}`)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabContext>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5em" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button variant="outlined" disabled={fileUploadStage === FILE_UPLOAD_STAGE.ANALYSIS && errorMessage === ""} onClick={handleCancel}>
            {translate("button.cancel")}
          </Button>
          {activeTab === "Select" && (
            <Button
              disabled={selectedFileInfos.length === 0}
              onClick={() => {
                /** this callback is defined in {@link DataSourcePanel} */
                props.onFilesReady(selectedFileInfos)
              }}
            >
              {translate("button.continue")}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
