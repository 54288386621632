import { createBrowserHistory } from "history"
import * as en_translations from "resources/json/translations/en.json"
import * as es_translations from "resources/json/translations/es.json"
import * as fr_translations from "resources/json/translations/fr.json"
import { DEFAULT_LOCALE } from "./language/model"
import { JAppServiceConfig } from "./model"

// @WARNING This state should only be used in the current directory

export const appCFG: JAppServiceConfig = {
  history: {
    browserHistory: createBrowserHistory(),
    currentHash: window.location.hash,
    currentHashParameters: {},
    allListeners: [],
    nextListenerId: 0
  },
  language: {
    defaultLocale: DEFAULT_LOCALE,
    translationsByLocale: {
      en: en_translations,
      fr: fr_translations,
      es: es_translations
    }
  },
  localStorageAvailable: true,
  restBaseUrl: undefined
}
