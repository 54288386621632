import { store } from "app/store/store"
import { LOGIN_PATH } from "page/model"
import { activatePageByPathInStore } from "page/tools/common"
import React from "react"
import { Navigate, Routes as ReactRouterRoutes, Route, useLocation } from "react-router-dom"
import { Login } from "user/components/Login"
import { MEMBER_ROLES } from "user/model"
import { userSVC } from "user/service"
import { doesCurrentUserHasAtLeastRole } from "user/tools/permissions"
import { Forbidden } from "./Forbidden"
import { MainLayout } from "./MainLayout"
import { NotFound } from "./NotFound"

const ProtectedRoute = ({ minimumRequiredRole, children }: { minimumRequiredRole: MEMBER_ROLES; children: JSX.Element }) => {
  const location = useLocation()
  if (!userSVC.isLoggedIn()) {
    return <Navigate to={{ pathname: LOGIN_PATH }} state={{ from: location }} replace />
  } else if (!doesCurrentUserHasAtLeastRole(minimumRequiredRole)) {
    return <Forbidden />
  }
  return children
}

export const Routes = () => {
  const location = useLocation()
  React.useEffect(() => {
    activatePageByPathInStore(location.pathname)
  })
  return (
    <ReactRouterRoutes>
      <Route element={<MainLayout />}>
        {store.getState().page.pages.map(page => {
          const elem = (
            <ProtectedRoute minimumRequiredRole={page.minimumRequiredRole}>
              <page.jsxElement />
            </ProtectedRoute>
          )
          return <Route path={page.path} key={page.path} element={elem} />
        })}
      </Route>
      <Route path={LOGIN_PATH} element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </ReactRouterRoutes>
  )
}
