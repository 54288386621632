import { BrowserHistory } from "history"
import { LOCALES } from "jmapcloud-types"
import { JEventModule } from "./event/model"
import { JHistoryListenerItem } from "./history/model"
import { JTranslationsByLocale } from "./language/model"
import { store } from "./store/store"
import { JDateService } from "./tool/date/model"
import { JLocalStorageService } from "./tool/local-storage/model"

export const APP_CONTAINER_ID: string = "app-container"
export const TOKEN_LOCAL_STORAGE = "token"
export const TOKEN_URL_PARAM: string = "token"

export enum APP_EVENTS {
  STORE_READY = "STORE_READY",
  APP_READY = "APP_READY"
}

export type JRootState = ReturnType<typeof store.getState>

export interface JToolService {
  Date: JDateService
  LocalStorage: JLocalStorageService
  loadJSFile(fileUrl: string): Promise<void>
}

export interface JAppServiceConfig {
  history: {
    browserHistory: BrowserHistory
    currentHash: string
    currentHashParameters: { [key: string]: string }
    allListeners: JHistoryListenerItem[]
    nextListenerId: number
  }
  language: {
    translationsByLocale: JTranslationsByLocale
    defaultLocale?: LOCALES
  }
  localStorageAvailable: boolean
  restBaseUrl: string | undefined
}

export interface JMainService {
  getVersion(): string
}

export interface JAppEventModule extends JEventModule {
  on: {
    storeReady(listenerId: string, fn: () => void): void
    appReady(listenerId: string, fn: () => void): void
  }
}
export interface JErrorResponse {
  response: {
    status: number
    data: {
      message: string
      errors: string[]
    }
  }
}
