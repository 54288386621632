import { Theme } from "@mui/material"
import { ACTIONS } from "app/store/actions"
import { Action } from "redux"

export interface JActionSetSidePanelVisible extends Action {
  visible: boolean
}

export interface JActionSetSidePanelBasemapSectionOpened extends Action {
  visible: boolean
}

export interface JActionSetTheme extends Action {
  theme: Theme
}

export const uiActionCreator = {
  reset(): Action {
    return {
      type: ACTIONS.APP_UI_RESET_STATE
    }
  },
  setTheme(theme: Theme): JActionSetTheme {
    return {
      type: ACTIONS.APP_UI_SET_THEME,
      theme
    }
  }
}
