import { Box, CircularProgress, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { JLayer, JProject } from "jmapcloud-types"
import { getLayer, getProject } from "project/utils"
import React from "react"
import { JDataSourceReference } from "spatialdatasource/model"

interface Props {
  references: JDataSourceReference
}

export const DataSourceReferences = (props: Props) => {
  const [projects, setProjects] = React.useState<JProject[]>()
  const [layers, setLayers] = React.useState<JLayer[]>()
  const [loading, setLoading] = React.useState(false)
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("sds.delete.message.error"))

  const projectIds = Object.keys(props.references.projects)

  React.useEffect(() => {
    const fetchData = async () => {
      resetError()
      setLoading(true)
      try {
        const [fetchedProjects, fetchedLayers] = await Promise.all([
          Promise.all(projectIds.map(projectId => getProject(projectId))),
          Promise.all(projectIds.flatMap(projectId => props.references.projects[projectId].layers.map((layerId: string) => getLayer(projectId, layerId))))
        ])
        setProjects(fetchedProjects)
        setLayers(fetchedLayers)
      } catch (error: any) {
        console.error(error)
        handleError(error)
      } finally {
        setLoading(false)
      }
    }
    if (Object.keys(props.references.projects).length > 0) {
      fetchData()
    }
  }, [props.references])

  if (loading) {
    return (
      <Box>
        <CircularProgress sx={{ margin: "1rem" }} size={"0.75rem"} />
      </Box>
    )
  }
  if (hasError) {
    return <Typography>{errorMessage}</Typography>
  }
  if (!projects || !layers) {
    return <Typography>{translate("sds.delete.message.noReferences")}</Typography>
  }
  return (
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      {projectIds.map(projectId => {
        const project = projects.find(p => p.id === projectId)
        const layersInProject = props.references.projects[projectId].layers
          .map(layerId => {
            const layer = layers.find(l => l.id === layerId)
            return layer.name[project.defaultLanguage] || ""
          })
          .filter(Boolean)

        const layerLabel = translate(`label.layer${layersInProject.length > 1 ? "s" : ""}`)
        const layersName = layersInProject.join(", ")
        return (
          <li key={projectId} style={{ display: "list-item" }}>
            <Typography>{`${translate("label.project")}: ${project.name[project.defaultLanguage]} (${layerLabel}: ${layersName})`}</Typography>
          </li>
        )
      })}
    </ul>
  )
}
