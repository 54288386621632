import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { JAppErrorDialogProps } from "message/model"
import { setErrorDialogProps } from "message/store"
import React from "react"

export const ErrorDialog = (props: JAppErrorDialogProps) => {
  const dispatch = useAppDispatch()

  return (
    <Dialog open>
      <DialogTitle
        sx={{
          minWidth: "20rem"
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose?.()
            dispatch(setErrorDialogProps(null))
          }}
          color="primary"
        >
          {translate("button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
