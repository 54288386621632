import { store } from "app/store/store"
import { localStorageSVC } from "app/tool/local-storage/service"
import { LOCALES } from "jmapcloud-types"
import { appCFG } from "../../config"
import { DEFAULT_LOCALE, JNavigator, LAST_LOCALE_LOCAL_STORAGE_ID, USER_CHANGE_LOCALE_LOCAL_STORAGE_ID } from "../model"
import { languageActionCreator } from "../store/actions"
import { getOverridenLocaleFromUrlOrOptions } from "./options"

function getBrowserLocale(): LOCALES {
  const nav = navigator as JNavigator
  let newLocale: LOCALES = nav.userLanguage ? (nav.userLanguage as LOCALES) : (nav.language as LOCALES)
  if (newLocale.length > 2) {
    newLocale = newLocale.substr(0, 2) as LOCALES
  }
  return isValidLocale(newLocale) ? newLocale : DEFAULT_LOCALE
}

export function isValidLocale(locale: LOCALES): boolean {
  return typeof locale === "string" && Object.keys(appCFG.language.translationsByLocale).indexOf(locale) > -1
}

export function setCurrentLocaleFromContext(): void {
  const defaultLocale = getBrowserLocale()
  const overridenLocale = getOverridenLocaleFromUrlOrOptions()
  if (overridenLocale && isValidLocale(overridenLocale)) {
    store.dispatch(languageActionCreator.setLocale(overridenLocale))
    return
  }
  const userChangeLocale = localStorageSVC.get(USER_CHANGE_LOCALE_LOCAL_STORAGE_ID) as LOCALES
  if (userChangeLocale && isValidLocale(userChangeLocale)) {
    localStorageSVC.set(LAST_LOCALE_LOCAL_STORAGE_ID, userChangeLocale)
    localStorageSVC.remove(USER_CHANGE_LOCALE_LOCAL_STORAGE_ID)
    store.dispatch(languageActionCreator.setLocale(userChangeLocale))
    return
  }
  const lastUsedLocale = localStorageSVC.get(LAST_LOCALE_LOCAL_STORAGE_ID) as LOCALES
  if (lastUsedLocale && isValidLocale(lastUsedLocale)) {
    store.dispatch(languageActionCreator.setLocale(lastUsedLocale))
  } else {
    // use browser locale as seed
    store.dispatch(languageActionCreator.setLocale(defaultLocale))
    localStorageSVC.set(LAST_LOCALE_LOCAL_STORAGE_ID, defaultLocale)
  }
}

export function getLocale(): LOCALES {
  return store.getState().language.locale
}
