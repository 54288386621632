import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { JStudioState } from "./model"
import { LOCALES } from "jmapcloud-types"

const initialStudioState: JStudioState = {
  projectId: null,
  projectName: "",
  defaultLanguage: "en"
}

interface ProjectPayload {
  id: string
  name: string | undefined
  defaultLanguage: LOCALES
}

const studioSlice = createSlice({
  name: "studio",
  initialState: initialStudioState,
  reducers: {
    setProject(state, action: PayloadAction<ProjectPayload>) {
      state.projectId = action.payload.id
      state.projectName = action.payload.name
      state.defaultLanguage = action.payload.defaultLanguage
    }
  }
})

export const { setProject } = studioSlice.actions
export default studioSlice.reducer
