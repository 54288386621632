import { translate } from "app/language/service"
import { messageSVC } from "message/service"
import { organizationEventModule } from "organization/event"
import { JOrganizationInvitation, JOrganizationInvitationCreateParams, ORGANIZATION_EVENTS } from "organization/model"
import { organizationRPO } from "organization/repository"
import { getUserOrganization } from "user/tools/common"

export function getOrganizationInvitations(): Promise<JOrganizationInvitation[]> {
  return organizationRPO.getOrganizationInvitations(getUserOrganization().id)
}

export function getOrganizationInvitation(invitationId: string): Promise<JOrganizationInvitation> {
  if (typeof invitationId !== "string" || invitationId === "") {
    return Promise.reject("invitationId must be a non empty  object")
  }
  return organizationRPO.getOrganizationInvitation(getUserOrganization().id, invitationId)
}

export function createOrganizationInvitation(params: JOrganizationInvitationCreateParams): Promise<JOrganizationInvitation> {
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (typeof params.inviteeEmail !== "string" || params.inviteeEmail === "") {
    return Promise.reject("param inviteeEmail must be a non empty  object")
  }
  if (!Array.isArray(params.roles)) {
    return Promise.reject("param roles must be an array")
  }
  return organizationRPO.createOrganizationInvitation(getUserOrganization().id, params)
}

export function deleteOrganizationInvitation(invitationId: string): Promise<boolean> {
  if (typeof invitationId !== "string" || invitationId === "") {
    return Promise.reject("invitationId must be a non empty  object")
  }
  return new Promise<boolean>((resolve, reject) => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.delete"),
      cancelButtonLabel: translate("button.cancel"),
      isCancelDefault: true,
      title: translate("organization.invitation.delete.title"),
      message: translate("organization.invitation.delete.message"),
      onCancel: () => resolve(false),
      onSuccess: () => {
        organizationRPO
          .deleteOrganizationInvitation(getUserOrganization().id, invitationId)
          .then(() => {
            messageSVC.success(translate("organization.invitation.delete.success"))
            organizationEventModule.notify(ORGANIZATION_EVENTS.DELETE_INVITATION, { invitationId })
            resolve(true)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
    })
  })
}
