import { JToolService } from "../model"
import { dateSVC } from "./date/service"
import { localStorageSVC } from "./local-storage/service"
import { loadJSFile } from "./utils"

export const toolSVC: JToolService = {
  Date: dateSVC,
  LocalStorage: localStorageSVC,
  loadJSFile(fileUrl: string): Promise<void> {
    if (typeof fileUrl !== "string") {
      throw Error("Invalid fileUrl param")
    }
    return loadJSFile(fileUrl)
  }
}
