import { appCFG } from "app/config"
import { getParametersFromString } from "./tools/common"

const history = appCFG.history

export function startHistoryListeners(): void {
  // it listen for all url changes
  history.browserHistory.listen(listener => {
    const newHash: string = listener.location.hash
    const newHashParameters = getParametersFromString(newHash.substr(1, newHash.length)) // remove "#" at the beginning
    if (history.currentHash === newHash) {
      return
    }
    if (history.allListeners.length > 0) {
      // here url hash has changed, we'll notify hash listener if exists
      const changesByParameterName: {
        [parameterName: string]: { oldValue: string | undefined; newValue: string | undefined }
      } = {}
      const newParameterNames: string[] = Object.keys(newHashParameters)
      const currentParameterNames: string[] = Object.keys(history.currentHashParameters)
      for (const currentParameterName of currentParameterNames) {
        const oldValue = history.currentHashParameters[currentParameterName]
        const newValue = newHashParameters[currentParameterName]
        if (oldValue !== newValue) {
          changesByParameterName[currentParameterName] = { oldValue, newValue }
        }
        newParameterNames.remove(currentParameterName)
      }
      for (const newParameterName of newParameterNames) {
        changesByParameterName[newParameterName] = {
          oldValue: undefined,
          newValue: newHashParameters[newParameterName]
        }
      }
      const changedPropertyNames: string[] = Object.keys(changesByParameterName)
      for (const changedParameterName of changedPropertyNames) {
        const change = changesByParameterName[changedParameterName]
        const oldValue: string | undefined = change.oldValue
        const newValue: string | undefined = change.newValue
        const changededListeners = history.allListeners.filter(l => l.parameterName === changedParameterName)
        for (const changedListener of changededListeners) {
          try {
            changedListener.fn(oldValue, newValue)
          } catch (ex) {
            console.error(`Listener id="${changedListener.id}" of parameter="${changedListener.parameterName}" has failed`, ex)
          }
        }
      }
    }
    history.currentHash = newHash
    history.currentHashParameters = newHashParameters
  })
}
