import { getUsername } from "./common"

export function checkPreferenceName(name: string) {
  if (typeof name !== "string") {
    throw Error("The preference name parameter is incorrect")
  }
  if (name === "") {
    throw Error("The preference name parameter is missing")
  }
}

export function checkUsername(username: string) {
  if (typeof username !== "string" || username === "") {
    throw Error("Invalid username")
  }
}

export function getQualifiedPreferenceName(preferenceName: string) {
  checkPreferenceName(preferenceName)
  const username = getUsername()
  checkUsername(username)
  return `${username}.${preferenceName}`
}
