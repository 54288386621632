import { GridFilterModel, GridRowId, GridSortModel } from "@mui/x-data-grid"
import * as tus from "tus-js-client"
import { JDataGridPagedResponse, JDataGridState } from "ui/tools/grid"

export const enum VECTOR_DATA_FILE_FORMATS {
  CSV = "CSV",
  GEOJSON = "GEOJSON",
  GML = "GML",
  DWG = "DWG",
  DXF = "DXF",
  SHAPE = "SHAPE",
  FILEGDB = "FILEGDB",
  GEOPACKAGE = "GEOPACKAGE",
  KML = "KML",
  MAPINFO = "MAPINFO"
}

export enum FILE_INFO_STATUSES {
  WAITING = "WAITING",
  ANALYZING = "ANALYZING",
  ANALYZED = "ANALYZED",
  ERROR = "ERROR",
  UPLOADING = "UPLOADING",
  FINISHING_UPLOAD = "FINISHING_UPLOAD",
  UPLOADED = "UPLOADED"
}

export enum VIRTUAL_FILE_INFO_STATUSES {
  RESUMABLE = "RESUMABLE",
  PAUSED = "PAUSED"
}

export enum FILE_INFO_TYPES {
  VECTOR_DATA = "VECTOR_DATA",
  RASTER_DATA = "RASTER_DATA"
}

export interface JVectorFileInfoLayerAttribute {
  id?: GridRowId
  originalName: string
  standardizedName: string
  type: string
}

export interface JVectorFileInfoLayerIndexedAttribute {
  name: string
  indexed: boolean
}

export interface JVectorFileInfoLayer {
  name: string
  crs: string | null
  geometryTypes: string[]
  fileAttributes: JVectorFileInfoLayerAttribute[]
}

export interface JFileInfo {
  id: string
  filename: string
  fileSize: number // in bytes
  type: FILE_INFO_TYPES
  createdBy: string
  creationDate: Date
  lastModificationDate: Date
  lastModifiedBy: string
  status: FILE_INFO_STATUSES
  jobId: string
  organizationId: string
  metadata: any
}

export interface JVectorFileInfo extends JFileInfo {
  type: FILE_INFO_TYPES.VECTOR_DATA
  metadata: {
    fileNames: string[]
    fileType: VECTOR_DATA_FILE_FORMATS // Format column
    layers: JVectorFileInfoLayer[]
    boundingBox: {
      minX: number
      minY: number
      maxX: number
      maxY: number
    }
    elementsCount: number
    fileAttributes: JVectorFileInfoLayerAttribute[] | null
    crs: string | null
    type: "VECTOR_METADATA"
  }
}

export interface JRasterFileInfo extends JFileInfo {
  type: FILE_INFO_TYPES.RASTER_DATA
  metadata: {
    title: string
    name: string
    crs: string | null
    fileType: string
    isCOG: boolean
    size: number[]
    files: string[]
    bbox: {
      minx: number
      miny: number
      maxx: number
      maxy: number
    }
    wgs84Extent: Record<string, number>
    type: "RASTER_METADATA"
  }
}

export interface JFileRepository {
  get(organizationId: string, page: number, size: number, sort: GridSortModel, filter: GridFilterModel): Promise<JDataGridPagedResponse<JFileInfo>>
  getOne(organizationId: string, fileId: string): Promise<JFileInfo>
  delete(organizationId: string, fileId: string): Promise<void>
}

export interface JFileGridState extends JDataGridState {
  uploadPercentCompletedByFileId: Record<string, number>
  uploadByFileId: Record<string, tus.Upload>
  uploadIsPausedByFileId: Record<string, boolean>
  uploadQueue: File[]
}
