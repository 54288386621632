import { store } from "app/store/store"
import { JAPP_MESSAGE_SEVERITIES, JAppMessageOptions } from "message/model"
import { setConfirmDialogProps } from "./store"

export function closeConfirmDialog(): void {
  store.dispatch(setConfirmDialogProps(null))
}

export function setMessageOptionsSeverity(severity: JAPP_MESSAGE_SEVERITIES, options?: JAppMessageOptions): JAppMessageOptions {
  if (options !== undefined && typeof options !== "object") {
    throw Error("Parameter options must be an object")
  }
  if (!options) {
    options = {}
  }
  options.severity = severity
  return options
}
