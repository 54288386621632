import DateFnsUtils from "@date-io/date-fns"
import { DEFAULT_LOCALE } from "app/language/model"
import { languageSVC } from "app/language/service"
import { store } from "app/store/store"
import { Duration, add, format, formatDistanceToNow as formatDistanceToNowFns, fromUnixTime, isAfter, isBefore, parseISO, sub } from "date-fns"
import {
  DATE_FNS_DATETIME_FORMAT_BY_JMAP_LOCALE,
  DATE_FNS_DATE_FORMAT_BY_JMAP_LOCALE,
  DATE_FNS_LOCALE_BY_JMAP_LOCALE,
  DATE_FNS_LONG_DATETIME_FORMAT_BY_JMAP_LOCALE,
  DATE_FNS_LONG_DATE_FORMAT_BY_JMAP_LOCALE,
  IS_12_HOURS_TIME_BY_JMAP_LOCALE,
  JDateFormatParams,
  JDateLike,
  JDateUnit,
  JUtilsByLocale
} from "../model"

// TODO: https://k2geospatial.atlassian.net/browse/JMAP8-1663
// getDateFnsUtils() does not seem to be used anymore, should we remove it and all its siblings?

class FrLocalizedUtils extends DateFnsUtils {
  public getDatePickerHeaderText(date: Date) {
    return format(date, "d MMM yyyy", { locale: this.locale })
  }
}

class EsLocalizedUtils extends DateFnsUtils {
  public getDatePickerHeaderText(date: Date) {
    return format(date, "d MMM yyyy", { locale: this.locale })
  }
}

const UTILS_BY_LOCALE: JUtilsByLocale = {
  en: DateFnsUtils,
  fr: FrLocalizedUtils,
  es: EsLocalizedUtils
}

export function getDateFnsUtils(): DateFnsUtils {
  return UTILS_BY_LOCALE[languageSVC.getLocale()]
}

export function getDateFnsDateFormat(displayTime?: boolean, longFormat?: boolean): string {
  const formatMap = displayTime
    ? longFormat
      ? DATE_FNS_LONG_DATETIME_FORMAT_BY_JMAP_LOCALE
      : DATE_FNS_DATETIME_FORMAT_BY_JMAP_LOCALE
    : longFormat
    ? DATE_FNS_LONG_DATE_FORMAT_BY_JMAP_LOCALE
    : DATE_FNS_DATE_FORMAT_BY_JMAP_LOCALE
  const jmapLocale = store.getState().language.locale
  const dateFnsDateFormat = formatMap[jmapLocale]
  if (dateFnsDateFormat) {
    return dateFnsDateFormat
  }
  console.warn(`getDateFnsDateFormat(): unknown locale "${jmapLocale}"`)
  return formatMap[DEFAULT_LOCALE]
}

export function is12HoursTimeFormat(): boolean {
  const jmapLocale = store.getState().language.locale
  const is12Hours = IS_12_HOURS_TIME_BY_JMAP_LOCALE[jmapLocale]
  if (is12Hours !== undefined) {
    return is12Hours
  }
  console.warn(`is12HoursTimeFormat(): unknown locale "${jmapLocale}"`)
  return IS_12_HOURS_TIME_BY_JMAP_LOCALE[DEFAULT_LOCALE]
}

export function getDateFromISOString(isoDate: string): Date {
  if (typeof isoDate !== "string" || !isoDate) {
    throw Error("You must provide a non empty string (ISO) to get the date")
  }
  return parseISO(isoDate)
}

export function formatDate(date: number | Date | string, params?: JDateFormatParams): string {
  if (!date) {
    return ""
  }
  if (typeof date === "string") {
    if (!date) {
      return ""
    }
    date = getDateFromISOString(date)
  }
  if (typeof date !== "number" && !(date instanceof Date)) {
    return ""
  }
  const result = format(date, getDateFnsDateFormat(params && params.displayTime === true, params && params.longFormat === true), {
    locale: getDateFnsLocale()
  })
  return params && typeof params.prefix === "string" && params.prefix !== "" ? `${params.prefix}${result}` : result
}

export function formatDistanceToNow(date: Date | number | string, params?: JDateFormatParams): string {
  if (typeof date === "string") {
    if (!date) {
      return ""
    }
    date = getDateFromISOString(date)
  }
  if (typeof date !== "number" && !(date instanceof Date)) {
    return ""
  }
  let result: string
  result = formatDistanceToNowFns(date, {
    locale: getDateFnsLocale(),
    addSuffix: true
  })
  if (params && params.prefix) {
    result = `${params.prefix}${result}`
  } else if (result && result.length > 2) {
    result = result[0].toUpperCase() + result.slice(1)
  }
  return result
}

export function getDateFnsLocale(): any {
  const jmapLocale = store.getState().language.locale
  const dateFnsLocale = DATE_FNS_LOCALE_BY_JMAP_LOCALE[jmapLocale]
  if (dateFnsLocale) {
    return dateFnsLocale
  }
  console.warn(`getDateFnsLocale(): unknown locale "${jmapLocale}"`)
  return DATE_FNS_LOCALE_BY_JMAP_LOCALE[DEFAULT_LOCALE]
}

export function isDateBefore(date1: JDateLike, date2: JDateLike, checkTime?: boolean): boolean {
  date1 = checkTime ? getDate(date1) : resetTime(getDate(date1))
  date2 = checkTime ? getDate(date2) : resetTime(getDate(date2))
  return isBefore(date1, date2)
}

export function isDateAfter(date1: JDateLike, date2: JDateLike, checkTime?: boolean): boolean {
  date1 = checkTime ? getDate(date1) : resetTime(getDate(date1))
  date2 = checkTime ? getDate(date2) : resetTime(getDate(date2))
  return isAfter(date1, date2)
}

export function dateAdd(date: JDateLike, amount: number, timeUnit: JDateUnit): Date {
  return add(getDate(date), getDuration(amount, timeUnit))
}

export function dateSubstract(date: JDateLike, amount: number, timeUnit: JDateUnit): Date {
  return sub(getDate(date), getDuration(amount, timeUnit))
}

export function getDate(date: JDateLike): Date {
  if (typeof date === "string" && date !== "") {
    return getDateFromISOString(date)
  }
  if (typeof date === "number") {
    return fromUnixTime(date / 1000)
  }
  if (date instanceof Date) {
    return new Date(date)
  }
  throw Error(`Cannot convert date "${date}"`)
}

function resetTime(date: Date): Date {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

function getDuration(amount: number, timeUnit: JDateUnit): Duration {
  const duration: Duration = {}
  switch (timeUnit) {
    case "seconds": {
      duration.seconds = amount
      break
    }
    case "minutes": {
      duration.minutes = amount
      break
    }
    case "hours": {
      duration.hours = amount
      break
    }
    case "days": {
      duration.days = amount
      break
    }
    case "weeks": {
      duration.weeks = amount
      break
    }
    case "months": {
      duration.months = amount
      break
    }
    case "years": {
      duration.years = amount
      break
    }
    default: {
      throw Error(`Time unit ${timeUnit} not found. Choose in: seconds, minutes, hours, days, weeks, months, years`)
    }
  }
  return duration
}
