import { createAuth0Client } from "@auth0/auth0-spa-js"

export async function willRedirectToAuth0Invitation(): Promise<boolean> {
  try {
    const searchString: string = window.location.search
    if (!searchString) {
      return false
    }

    // ex: "?organization=ok&invitation=xxx"
    const urlSearchParams = new URLSearchParams(searchString)
    const organizationId = urlSearchParams.get("organization")
    const organizationName = urlSearchParams.get("organization_name")
    const invitation = urlSearchParams.get("invitation")
    const rawDomain = urlSearchParams.get("domain")
    const domain = decodeURIComponent(decodeURIComponent(rawDomain ?? ""))
    const clientId = urlSearchParams.get("client_id")
    const locale = urlSearchParams.get("locale")

    if (organizationId && invitation && domain && clientId) {
      const auth0 = await createAuth0Client({
        domain,
        clientId
      })
      const redirectUrl: string = location.href.split("?")[0] // remove all search params
      auth0.loginWithRedirect({
        authorizationParams: {
          ui_locales: locale ? locale : undefined,
          organization: organizationId,
          invitation,
          redirect_uri: `${redirectUrl}?isLandingPage=true&domain=${rawDomain}&client_id=${clientId}&organization_name=${organizationName}`
        }
      })
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error("unable to check for auth0 invitation", error)
    return false
  }
}
