import { ROLES } from "./model"

export const getAvatarLetterFromRole = (role: ROLES): string => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return "AM"
    case ROLES.ORG_ADMIN:
      return "A"
    case ROLES.ORG_EDITOR:
      return "E"
    case ROLES.ORG_VIEWER:
      return "V"
    case ROLES.SYS_ADMIN:
      return "SA"
    default:
      return "?"
  }
}
