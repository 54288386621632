import { translate } from "app/language/service"
import { store } from "app/store/store"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"

interface JMemberGridRowMenuProps {
  memberId: string
  name: string
  onUpdateClick: () => any
  onShowEditDialog: () => any
  onClose: () => void
  onDeleteClick: () => any
}

export const MemberGridRowMenu = (props: JMemberGridRowMenuProps): JSX.Element => {
  const items: JButtonMenuItem[] = [
    {
      title: translate("member.details.tooltip"),
      onClick: () => {
        props.onShowEditDialog()
      }
    },
    {
      title: translate("member.update.tooltip"),
      disabled: props.memberId === store.getState().user.email,
      onClick: () => {
        props.onUpdateClick()
      }
    },
    {
      title: translate("member.delete.title"),
      disabled: props.memberId === store.getState().user.email,
      onClick: () => {
        props.onDeleteClick()
      }
    }
  ]
  return <ButtonMenu id={`member-menu-${props.memberId}`} small items={items} onClose={props.onClose} />
}
