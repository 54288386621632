import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, MenuItem, TextField } from "@mui/material"
import { translate } from "app/language/service"
import { JMember } from "member/model"
import memberRPO from "member/repository"
import { deleteMember, getAllMembers } from "member/tools/common"
import React from "react"
import { ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"
import { rolesIncludeMinimumMemberRole } from "user/tools/permissions"
import { messageSVC } from "message/service"

interface JMemberDeleteDialogProps {
  member: JMember
  onClose: () => void
  onDelete: () => void
}

export const MemberDeleteDialog = (props: JMemberDeleteDialogProps): JSX.Element => {
  const [newOwnerEmail, setNewOwnerEmail] = React.useState("")
  const [members, setMembers] = React.useState<JMember[]>([])
  const [isTransferringOwnership, setIsTransferringOwnership] = React.useState(false)
  const [isFetchingMember, setIsFetchingMember] = React.useState(false)
  const [isDeletingMember, setIsDeletingMember] = React.useState(false)
  const [newOwnerRoleError, setNewOwnerRoleError] = React.useState(false)
  const [newOwnerRoleErrorMessage, setNewOwnerRoleErrorMessage] = React.useState("")
  React.useEffect(() => {
    getAllMembers(getUserOrganization().id).then(allMembers => setMembers(allMembers.filter(member => member.email !== props.member.email)))
  }, [])
  const handleDeleteMember = async () => {
    setIsDeletingMember(true)
    try {
      await deleteMember(props.member.id, getUserOrganization().id, isTransferringOwnership ? newOwnerEmail : undefined)
      props.onDelete()
    } catch (error) {
      console.error(error)
      messageSVC.error(translate("member.delete.error"))
    } finally {
      setIsDeletingMember(false)
      props.onClose()
    }
  }
  const handleTransferringOwnershipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTransferringOwnership(event.target.checked)
  }
  const handleNewOwnerEmailChange = async (event: any) => {
    let newOwner = members.find(member => member.email === event.target.value)
    if (newOwner) {
      //  always fetch the member on each menu item change for simpler processing
      // we need to fetch individual members since the list does not return roles for each member
      setIsFetchingMember(true)
      try {
        newOwner = await memberRPO.getById(event.target.value, getUserOrganization().id)
        if (rolesIncludeMinimumMemberRole(newOwner.roles, ROLES.ORG_EDITOR)) {
          setNewOwnerRoleError(false)
          setNewOwnerRoleErrorMessage("")
        } else {
          setNewOwnerRoleError(true)
          setNewOwnerRoleErrorMessage(translate("member.error.not.org.editor"))
        }
      } catch (error) {
        setNewOwnerRoleError(true)
        setNewOwnerRoleErrorMessage(translate("member.error.validating.member.role"))
      } finally {
        setIsFetchingMember(false)
      }
    }
    setNewOwnerEmail(newOwner.email ?? "")
  }
  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle
        sx={{
          minWidth: "20rem"
        }}
      >
        {translate("member.delete.message.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: translate("member.delete.message.confirm", { memberName: props.member.name })
          }}
        ></DialogContentText>
        <DialogContentText sx={{ paddingTop: "1rem" }}>{translate("member.delete.description")}</DialogContentText>
        <FormControl sx={{ flexDirection: "row", paddingTop: "1rem", alignItems: "flex-start" }}>
          <FormControlLabel
            sx={{ paddingRight: "3rem" }}
            control={<Checkbox value={isTransferringOwnership} onChange={handleTransferringOwnershipChange} />}
            label={translate("member.delete.transfer.ownership.label")}
            componentsProps={{ typography: { noWrap: true } }}
          />
          <TextField
            disabled={!isTransferringOwnership}
            sx={{ minWidth: "15rem" }}
            value={newOwnerEmail}
            FormHelperTextProps={{
              children: "  "
            }}
            error={isTransferringOwnership && newOwnerRoleError}
            helperText={isTransferringOwnership && newOwnerRoleErrorMessage ? newOwnerRoleErrorMessage : " "}
            onChange={handleNewOwnerEmailChange}
            select
          >
            {members.map(member => (
              <MenuItem key={`member-${member.email}`} value={member.email}>
                {member.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outlined" color="primary">
          {translate("button.cancel")}
        </Button>
        <Button onClick={handleDeleteMember} disabled={isTransferringOwnership && (isFetchingMember || !newOwnerEmail || newOwnerRoleError || isDeletingMember)}>
          {translate("button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
