import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { JEventModule } from "app/event/model"
import { JDataGridPagedResponse } from "ui/tools/grid"

export enum JOB_STATUS {
  ACCEPTED = "accepted",
  RUNNING = "running",
  SUCCESS = "successful",
  FAILED = "failed",
  DISMISSED = "dismissed"
}

export enum JOB_EVENTS {
  CANCEL = "cancel"
}

export const CANCELABLE_JOB_STATUSES: JOB_STATUS[] = [JOB_STATUS.ACCEPTED, JOB_STATUS.RUNNING]

export interface JProcess {
  id: string
  title: string
  description: string
  jobControlOptions: ["sync-execute", "async-execute", "dismiss"]
  outputTransmission: ["value", "reference"]
}

export interface JJob {
  id: string
  organizationId: string
  // organizationName: string
  status: JOB_STATUS
  processId: string
  type: string
  message: string
  creationDate: Date
  startDate: Date | undefined
  finishDate: Date | undefined
  updateDate: Date | undefined
  progress: number
  isProcessCancelable: boolean
  inputs: any
}

export interface JJobRepository {
  getAllProcesses(organizationId: string): Promise<JProcess[]>
  get(organizationId: string, processes: JProcess[], page: number, size: number, sort: GridSortModel, filter: GridFilterModel): Promise<JDataGridPagedResponse<JJob>>
  getJob(organizationId: string, jobId: string): Promise<JJob>
  cancelJob(organizationId: string, jobId: string): Promise<void>
  executeProcess(organizationId: string, process: string, params: any): Promise<JJob>
}

export interface JJobEventCancelParams {
  jobId: string
}

export interface JJobEventModule extends JEventModule {
  on: {
    cancel(listenerId: string, fn: (params: JJobEventCancelParams) => void): void
  }
}
