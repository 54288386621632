import { Stack } from "@mui/system"
import { useAppSelector } from "app/store/hooks"
import React from "react"
import { ProjectMainContent } from "./ProjectMainContent"
import { ProjectMainHeader } from "./ProjectMainHeader"
import { ProjectMvtTileCacheDialog } from "./ProjectMvtTileCacheDialog"
import { ProjectPermissionDialog } from "./ProjectPermissionDialog"

export const ProjectPage = (): JSX.Element => {
  const { projectForWhichToEditPermissions, projectForWhichToManageMvtCache } = useAppSelector(state => ({
    projectForWhichToEditPermissions: state.project.projectForWhichToEditPermissions,
    projectForWhichToManageMvtCache: state.project.projectForWhichToManageMvtCache
  }))

  return (
    <Stack height="100%" padding="2rem">
      {projectForWhichToEditPermissions && <ProjectPermissionDialog project={projectForWhichToEditPermissions} />}
      {projectForWhichToManageMvtCache && <ProjectMvtTileCacheDialog project={projectForWhichToManageMvtCache} />}
      <ProjectMainHeader />
      <ProjectMainContent />
    </Stack>
  )
}
