import { languageSVC, translate } from "app/language/service"
import { store } from "app/store/store"
import { v4 as uuidv4 } from "uuid"
import { DEFAULT_MESSAGE_DURATION, JAPP_MESSAGE_SEVERITIES, JAppConfirmDialogProps, JAppErrorDialogProps, JAppMessageOptions, JAppMessageService } from "./model"
import { addMessage, addOverlayMessage, deleteOverlayMessages, setConfirmDialogProps, setErrorDialogProps } from "./store"
import { setMessageOptionsSeverity } from "./utils"

export const messageSVC: JAppMessageService = {
  error(message: string, options?: JAppMessageOptions): void {
    this.display(message, setMessageOptionsSeverity(JAPP_MESSAGE_SEVERITIES.ERROR, options))
  },
  warning(message: string, options?: JAppMessageOptions): void {
    this.display(message, setMessageOptionsSeverity(JAPP_MESSAGE_SEVERITIES.WARNING, options))
  },
  info(message: string, options?: JAppMessageOptions): void {
    this.display(message, setMessageOptionsSeverity(JAPP_MESSAGE_SEVERITIES.INFO, options))
  },
  success(message: string, options?: JAppMessageOptions): void {
    this.display(message, setMessageOptionsSeverity(JAPP_MESSAGE_SEVERITIES.SUCCESS, options))
  },
  confirmDialog(confirmDialogProps: JAppConfirmDialogProps): void {
    if (!confirmDialogProps || typeof confirmDialogProps !== "object") {
      throw Error("Invalid confirmDialogProps")
    }
    if (!confirmDialogProps.message || typeof confirmDialogProps.message !== "string") {
      throw Error("'message' is not a non empty string")
    }
    if (typeof confirmDialogProps.isCancelDefault !== "undefined" && typeof confirmDialogProps.isCancelDefault !== "boolean") {
      throw Error("'isCancelDefault' is not a boolean")
    }
    if (!confirmDialogProps.onSuccess || typeof confirmDialogProps.onSuccess !== "function") {
      throw Error("'onSuccess' is not a function")
    }
    if (confirmDialogProps.onCancel && typeof confirmDialogProps.onCancel !== "function") {
      throw Error("'onCancel' is not a function")
    }
    if (confirmDialogProps.locale && !languageSVC.isValidLocale(confirmDialogProps.locale)) {
      throw Error("'locale' is not a valid locale")
    }
    confirmDialogProps.isInputMessage = Boolean(confirmDialogProps.isInputMessage)
    if (!confirmDialogProps.confirmButtonLabel || typeof confirmDialogProps.confirmButtonLabel !== "string") {
      confirmDialogProps.confirmButtonLabel = translate("button.ok", undefined, confirmDialogProps.locale)
    }
    if (!confirmDialogProps.cancelButtonLabel || typeof confirmDialogProps.cancelButtonLabel !== "string") {
      confirmDialogProps.cancelButtonLabel = translate("button.cancel", undefined, confirmDialogProps.locale)
    }
    store.dispatch(setConfirmDialogProps(confirmDialogProps))
  },
  errorDialog(props: JAppErrorDialogProps) {
    store.dispatch(setErrorDialogProps(props))
  },
  display(message: string, options?: JAppMessageOptions): void {
    if (typeof message !== "string") {
      throw Error("Parameter message is not a string")
    }
    if (message.trim() === "") {
      throw Error("Parameter message must be a non empty string")
    }
    if (options !== undefined && typeof options !== "object") {
      throw Error("Parameter options must be an object")
    }
    if (!options) {
      options = {}
    }
    options.severity = options.severity ?? JAPP_MESSAGE_SEVERITIES.INFO
    options.duration = options.duration !== undefined ? options.duration : DEFAULT_MESSAGE_DURATION
    store.dispatch(
      addMessage({
        id: uuidv4(),
        severity: options.severity,
        text: message,
        expired: false,
        duration: options.duration
      })
    )
  },
  displayWaitingOverlay(message: string): string {
    if (typeof message !== "string") {
      throw Error("Parameter message is not a string")
    }
    if (message.trim() === "") {
      throw Error("Parameter message must be a non empty string")
    }
    const id: string = uuidv4()
    store.dispatch(
      addOverlayMessage({
        id,
        text: message
      })
    )
    return id
  },
  closeWaitingOverlay(messageId?: string): void {
    const toDeleteMessageIds: string[] = []
    if (messageId !== undefined) {
      if (typeof messageId !== "string" || !messageId) {
        throw Error("Parameter message must be a non empty string")
      }
      toDeleteMessageIds.push(messageId)
    } else {
      const allMessageIds: string[] = store.getState().message.overlayMessages.map(message => message.id)
      if (allMessageIds.length === 0) {
        return
      }
      toDeleteMessageIds.push(...allMessageIds)
    }
    store.dispatch(deleteOverlayMessages(toDeleteMessageIds))
  }
}
