import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Stack, Tooltip } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { dateSVC } from "app/tool/date/service"
import { JJob, JOB_STATUS, JProcess } from "job/model"
import { reload, setFilterModel, setPage, setPageSize, setSortModel, setTotalRowCount } from "job/store"
import { getJobStatusChipLevel, getJobs, getProcesses, isJobCancelable } from "job/utils"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { StatusChip } from "ui/components/StatusChip"
import { useHoverableDataGridRows } from "ui/hooks"
import { getGridDateOperatorsForJMC, getGridIdOperatorsForJMC } from "ui/tools/grid"
import { JobGridRowActionMenu } from "./JobGridRowActionMenu"

export const JobGrid = (): JSX.Element => {
  const [jobs, setJobs] = React.useState<JJob[]>([])
  const [processes, setProcesses] = React.useState<JProcess[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [loadingError, setLoadingError] = React.useState<true | null>(null)

  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()

  const { page, pageSize, totalRowCount, sortModel, filterModel, reloadCounter } = useAppSelector(state => ({
    page: state.job.page,
    pageSize: state.job.pageSize,
    totalRowCount: state.job.totalRowCount,
    sortModel: state.job.sortModel,
    filterModel: state.job.filterModel,
    reloadCounter: state.job.reloadCounter
  }))
  const dispatch = useAppDispatch()

  // We first get the list of processes (only once), which is then used to modify the list of retrieved
  // jobs, every time a job query is performed
  React.useEffect(() => {
    setLoadingError(null)
    getProcesses()
      .then(ps => {
        setProcesses(ps)
      })
      .catch(error => {
        console.error(error)
        setLoadingError(true)
      })
  }, [])

  React.useEffect(() => {
    if (processes.length === 0) {
      // The query to get all processes is not done, for some reason
      return
    }
    setIsLoading(true)
    setLoadingError(null)
    getJobs(processes, page, pageSize, sortModel, filterModel)
      .then(resp => {
        dispatch(setTotalRowCount(resp.page.totalElements))
        setJobs(resp.result)
      })
      .catch(error => {
        console.error(error)
        setLoadingError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [page, pageSize, sortModel, filterModel, reloadCounter, processes])

  const columns: Array<GridColDef<JJob, any, any>> = [
    {
      field: "processId",
      headerName: translate("label.type"),
      minWidth: 200,
      flex: 2,
      type: "singleSelect",
      valueOptions: processes.map(p => p.id)
    },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, JJob, any>) => {
        if (params.id === hoveredRowId && isJobCancelable(params.row.status) && params.row.isProcessCancelable) {
          return <JobGridRowActionMenu onClose={() => setHoveredRowId(null)} jobId={params.row.id} />
        } else {
          return null
        }
      }
    },
    {
      field: "id",
      headerName: translate("label.id"),
      minWidth: 280,
      flex: 2,
      filterOperators: getGridIdOperatorsForJMC()
    },
    {
      field: "status",
      headerName: translate("label.status"),
      type: "singleSelect",
      valueOptions: Object.values(JOB_STATUS),
      renderCell: params => <StatusChip level={getJobStatusChipLevel(params.row.status)} label={translate(`job.status.${params.row.status}`)} />,
      minWidth: 100,
      flex: 1
    },
    {
      field: "message",
      headerName: translate("label.message"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 220,
      renderCell: params => (
        <Tooltip title={params.row.message}>
          <div>{params.row.message}</div>
        </Tooltip>
      ),
      flex: 2
    },
    {
      field: "progress",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: translate("label.progress"),
      minWidth: 100,
      renderCell: params => (params.row.progress ? `${params.row.progress}%` : "-"),
      flex: 1
    },
    {
      field: "creationDate",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: translate("label.creationDate"),
      type: "date",
      renderCell: params => dateSVC.format(params.row.creationDate, { displayTime: true }),
      minWidth: 140,
      flex: 1
    },
    {
      field: "startDate",
      headerName: translate("label.startDate"),
      filterOperators: getGridDateOperatorsForJMC(),
      type: "date",
      renderCell: params => dateSVC.format(params.row.startDate!, { displayTime: true }),
      minWidth: 140,
      flex: 1
    },
    {
      field: "finishDate",
      headerName: translate("label.finishDate"),
      filterOperators: getGridDateOperatorsForJMC(),
      type: "date",
      renderCell: params => dateSVC.format(params.row.finishDate!, { displayTime: true }),
      minWidth: 140,
      flex: 1
    },
    {
      field: "updateDate",
      headerName: translate("label.updateDate"),
      filterOperators: getGridDateOperatorsForJMC(),
      type: "date",
      renderCell: params => dateSVC.format(params.row.updateDate!, { displayTime: true }),
      minWidth: 140,
      flex: 1
    }
  ]

  return (
    <Stack height="100%" width="100%" alignItems="flex-start">
      <IconButton color="primary" size="small" onClick={() => dispatch(reload())}>
        <FontAwesomeIcon icon={light("refresh")} />
      </IconButton>
      <PortalDataGrid
        rowType="job"
        rows={jobs}
        columns={columns}
        loading={isLoading}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={totalRowCount}
        pageSize={pageSize}
        onPageSizeChange={s => dispatch(setPageSize(s))}
        page={page}
        onPageChange={p => dispatch(setPage(p))}
        error={loadingError}
        initialState={{
          sorting: {
            sortModel
          },
          filter: {
            filterModel
          }
        }}
        onSortModelChange={m => dispatch(setSortModel(m))}
        onFilterModelChange={m => dispatch(setFilterModel(m))}
        componentsProps={{
          row: rowHandlers
        }}
      />
    </Stack>
  )
}
