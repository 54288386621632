import { getDefaultLanguageState } from "app/language/store/state"
import { localizedThemes } from "ui/tools/theme"
import { JUiState } from "../model"

export function getDefaultUiState(): JUiState {
  const defaultTheme = localizedThemes[getDefaultLanguageState().locale].default
  return {
    theme: defaultTheme
  }
}
