import { CircularProgress, Tooltip } from "@mui/material"
import { translate } from "app/language/service"
import { deleteOrganizationInvitation } from "organization/tools/invitation"
import { messageSVC } from "message/service"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"

interface JOrganizationInvitationGridRowMenuProps {
  invitationId: string
  onShowEditDialog: () => void
  onClose: () => void
  onDelete: () => void
}

export const OrganizationInvitationGridRowMenu = (props: JOrganizationInvitationGridRowMenuProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const isDisabled: boolean = isDeleting
  const items: JButtonMenuItem[] = [
    {
      title: translate("organization.invitation.details.tooltip"),
      disabled: isDisabled,
      onClick: () => {
        if (isDisabled) {
          return
        }
        props.onShowEditDialog()
      }
    },
    {
      title: translate("organization.invitation.delete.title"),
      disabled: isDisabled,
      onClick: () => {
        if (isDisabled) {
          return
        }
        setIsDeleting(true)
        deleteOrganizationInvitation(props.invitationId)
          .then(isDeleted => {
            setIsDeleting(false)
            props.onDelete()
          })
          .catch(error => {
            console.error(error)
            messageSVC.error(translate("organization.invitation.delete.error"))
            setIsDeleting(false)
          })
      }
    }
  ]
  return isDeleting ? (
    <Tooltip title={translate("organization.invitation.deleting.title")}>
      <CircularProgress size={24} />
    </Tooltip>
  ) : (
    <ButtonMenu id={`organization-invitation-menu-${props.invitationId}`} small items={items} onClose={props.onClose} />
  )
}
