import { store } from "app/store/store"
import { DASHBOARD_PATH, JPage, JPageService, STUDIO_PATH } from "./model"
import { getPageByPath, refreshPages } from "./tools/common"

refreshPages(SHOW_STUDIO)

export const pageSVC: JPageService = {
  getAll(): JPage[] {
    return store.getState().page.pages.slice()
  },
  getActive(): JPage {
    return { ...store.getState().page.activePage }
  },
  showStudio(show: boolean) {
    if (store.getState().page.activePage.path === STUDIO_PATH && !show) {
      window.location.href = DASHBOARD_PATH
    }
    refreshPages(show)
  },
  getByPath: getPageByPath,
  existByPath(pagePath: string): boolean {
    return store.getState().page.pages.findByProperty("path", pagePath) !== undefined
  }
}
