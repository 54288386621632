import { LOCALES } from "jmapcloud-types"

export const DEFAULT_MESSAGE_DURATION = 5000
export const MESSAGE_WIDTH_IN_REM: string = "23rem"
export const CONFIRM_BUTTON_ID = "jmap-cloud-confirm-message-confirm-button"

export const enum JAPP_MESSAGE_SEVERITIES {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export interface JAppMessage {
  id: string
  text: string
  severity: JAPP_MESSAGE_SEVERITIES
  expired: boolean
  duration: number | null
}

export interface JAppOverlayMessage {
  id: string
  text: string
}

export interface JAppConfirmDialogProps {
  message: string
  title: string
  isCancelDefault?: boolean
  isInputMessage?: boolean
  isInputOptional?: boolean
  inputPlaceholder?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  hideCancelButton?: boolean
  locale?: LOCALES
  children?: JSX.Element
  onSuccess: (input: string) => any
  onCancel?: () => any
}

export interface JAppErrorDialogProps {
  message: string
  title: string
  onClose?: () => any
}

export interface JAppMessageOptions {
  severity?: JAPP_MESSAGE_SEVERITIES
  duration?: number | null
}

export interface JAppMessageState {
  messages: JAppMessage[]
  overlayMessages: JAppOverlayMessage[]
  confirmDialogProps: JAppConfirmDialogProps | null
  errorDialogProps: JAppErrorDialogProps | null
}

export interface JAppMessageService {
  error(message: string, options?: JAppMessageOptions): void
  warning(message: string, options?: JAppMessageOptions): void
  info(message: string, options?: JAppMessageOptions): void
  success(message: string, options?: JAppMessageOptions): void
  confirmDialog(props: JAppConfirmDialogProps): void
  errorDialog(props: JAppErrorDialogProps): void
  display(message: string, options?: JAppMessageOptions): void
  displayWaitingOverlay(message: string): string
  closeWaitingOverlay(messageId?: string): void
}
