import { Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { store } from "app/store/store"
import { JProject } from "jmapcloud-types"
import { getProjects } from "project/utils"
import React, { useState } from "react"
import { setProject } from "studio/store"

export const ProjectSelection = () => {
  const [projects, setProjects] = useState<JProject[]>([])
  React.useEffect(() => {
    getProjects(0, 25, [{ field: "name", sort: "asc" }], { items: [] })
      .then(resp => {
        setProjects(resp.result)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const selectProject = (project: JProject) => {
    store.dispatch(setProject({ id: project.id, name: project.name[project.defaultLanguage], defaultLanguage: project.defaultLanguage }))
  }
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"} width={"100%"} padding={"2rem"}>
      <Stack spacing={2} width={200} sx={{ margin: "auto" }}>
        {projects.map((p, index) => (
          <Box onClick={() => selectProject(p)} key={index} sx={{ cursor: "pointer" }} display={"flex"} flexDirection={"column"} padding={"1rem"} border={"1px solid black"}>
            <Typography key={index}>{p.name[p.defaultLanguage]}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
