import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { translate } from "app/language/service"
import { JMember } from "member/model"
import memberRPO from "member/repository"
import React, { useEffect, useState } from "react"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { TextLabel } from "ui/components/TextLabel"
import { getRoleChips } from "ui/utils"
import { MEMBER_ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"

interface JMemberDetailsDialogProps {
  member: JMember
  onClose: () => any
}

export const MemberDetailsDialog = (props: JMemberDetailsDialogProps) => {
  const [roles, setRoles] = useState(Array<MEMBER_ROLES>)
  const [rolesLoaded, setRolesLoaded] = useState(false)
  useEffect(() => {
    const fetchRoles = async () => {
      const memberRoles = (await memberRPO.getById(props.member.id, getUserOrganization().id)).roles
      setRoles(memberRoles)
      setRolesLoaded(true)
    }
    fetchRoles()
  }, [rolesLoaded])

  const { member, onClose } = props
  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }}>{translate("member.details.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextLabel value={member.name} label={translate("label.name")} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.email")} value={member.email} />
              </Grid>
              <Grid item xs={12}>
                <RichTextLabel label={translate("label.roles")} value={!rolesLoaded ? <CircularProgress size={"1rem"} /> : getRoleChips(roles)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate("button.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}
