export enum ACTIONS {
  APP_IS_LOADING_DATA = "APP_IS_LOADING_DATA",
  RESET = "RESET",
  ORGANIZATION_SET_CURRENT = "ORGANIZATION_SET_CURRENT",
  ORGANIZATION_SET_ALL = "ORGANIZATION_SET_ALL",
  ORGANIZATION_SET_ACTIVE_TAB = "ORGANIZATION_SET_ACTIVE_TAB",
  ORGANIZATION_SET_PAGE_SIZE = "ORGANIZATION_SET_PAGE_SIZE",
  APP_UI_RESET_STATE = "APP_UI_RESET_STATE",
  APP_UI_SET_THEME = "APP_UI_SET_THEME",
  LANGUAGE_SET_LOCALE = "LANGUAGE_SET_LOCALE",
  PAGE_SET_ALL = "PAGE_SET_ALL",
  PAGE_ACTIVATE = "PAGE_ACTIVATE",
  SERVER_SET_SERVER_INFO = "SERVER_SET_SERVER_INFO",
  SERVER_SET_SERVER_INFO_LOADING_ERROR = "SERVER_SET_SERVER_INFO_LOADING_ERROR",
  SERVER_SET_SERVER_INFO_IS_LOADING = "SERVER_SET_SERVER_INFO_IS_LOADING"
}
