import { Box, CircularProgress, Container, Theme, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { useAppSelector } from "app/store/hooks"
import { LOCALES } from "jmapcloud-types"
import { MessageCenter } from "message/components/MessageCenter"
import React from "react"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import bg from "resources/images/background/transitionPageBackground.jpeg"
import jmapCloudLogo from "resources/images/JMap-Cloud-Logo.svg"
import { JServerState } from "server/model"
import { CodeVersions } from "ui/components/CodeVersions"

interface JLoginConnectProps {
  serverState: JServerState
  locale: LOCALES // this prop is used to trigger refresh when no user is logged in and that the language changes
  isLoggingIn: boolean
  isUserLoggedIn: boolean
  userLoginError: string
  token: string
}

function LoginFn(props: JLoginConnectProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const { userSelectedOrganization } = useAppSelector(state => ({
    userSelectedOrganization: state.user.selectedOrganization
  }))

  const { serverState, isLoggingIn, userLoginError, isUserLoggedIn } = props

  const onAfterLogin = () => {
    const from = location.state ? location.state.from : "/"
    navigate(from)
  }

  React.useEffect(() => {
    if (userSelectedOrganization) {
      onAfterLogin()
    }
  }, [userSelectedOrganization])

  return (
    <Box
      sx={{
        "position": "relative",
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "padding": 0,
        "margin": 0,
        "boxShadow": "0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.2)",
        "width": "100%",
        "height": "100%",
        "& .jmap-main-panel-login-box > .MuiTextField-root": {
          marginBottom: "1rem",
          width: "100%"
        },
        "& .jmap-code-version": {
          marginBottom: "1rem"
        }
      }}
      id="jmap-login-panel"
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${bg})`
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "radial-gradient(circle farthest-corner at 50% 50%,rgba(39,39,38,.1) 65%,#272726),linear-gradient(180deg,#272726 10%,rgba(39,39,38,.7) 50%,#272726)"
        }}
      ></Box>
      <img
        style={{
          position: "absolute",
          top: "1rem",
          left: "9.5rem",
          maxHeight: "4rem"
        }}
        src={jmapCloudLogo}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          margin: 0,
          height: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          width: "100%",
          maxWidth: "100%",
          minWidth: "100%",
          zIndex: 1
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.background.paper,
            width: "37rem",
            maxWidth: "37rem",
            minHeight: "16rem",
            maxHeight: "16rem",
            paddingTop: "3rem",
            paddingBottom: "3rem"
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {serverState.hasLoadingError ? (
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginBottom: "1rem"
                }}
                color="error"
              >
                {translate("login.logging.in.error")}
              </Typography>
            ) : serverState.isLoading ? (
              <Container
                sx={{
                  display: "flex",
                  margin: 0,
                  padding: 0,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "1rem"
                  }}
                  color="textPrimary"
                >
                  {translate("login.logging.in")}
                </Typography>
                <CircularProgress color="primary" />
              </Container>
            ) : (
              <>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "1rem"
                  }}
                  className={"jmap-main-panel-login-title"}
                  color="textPrimary"
                >
                  {translate("login.title")}
                </Typography>
                <Box
                  sx={{
                    "display": "flex",
                    "flexDirection": "column",
                    "alignItems": "center",
                    "width": "70%",
                    "maxWidth": "70%",
                    "& .MuiInputBase-input": {
                      paddingLeft: ".5rem",
                      paddingRight: ".5rem"
                    }
                  }}
                  className="jmap-main-panel-login-box"
                  bgcolor="background.paper"
                  padding="1rem"
                  alignItems="center"
                  justifyContent="stretch"
                >
                  {isLoggingIn && (
                    <Typography
                      color="textPrimary"
                      sx={{
                        borderTop: "1rem",
                        borderBottom: "1rem"
                      }}
                    >
                      {translate("login.loading")}
                    </Typography>
                  )}
                  {userLoginError && (
                    <Typography
                      color="error"
                      sx={{
                        borderTop: "1rem",
                        borderBottom: "1rem",
                        textAlign: "center"
                      }}
                    >
                      {userLoginError}
                    </Typography>
                  )}
                </Box>
              </>
            )}
            <MessageCenter maxSize={true} />
          </Container>
          <CodeVersions />
        </Box>
      </Box>
    </Box>
  )
}

export const Login = connect(
  (state: JRootState) =>
    ({
      locale: state.language.locale,
      serverState: state.server,
      isLoggingIn: state.user.isLoggingIn,
      isUserLoggedIn: state.user.isUserLoggedIn,
      token: state.user.accessToken,
      userLoginError: state.user.userLoginError
    } as JLoginConnectProps)
)(LoginFn)
